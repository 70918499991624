@charset "UTF-8";

// ---------------------------
// Header
// ---------------------------

.l-header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    line-height: 1.6;
    width: 100%;

    @include mq(large, max, true) {
        background-color: $color-white;
        height: 50px;
    }

    .m-logo-sp {
        display: none;

        @include mq(large, max, true) {
            display: inline-block;
            width: 170px;
            margin-top: 15px;
            position: relative;
            left: 50%;
            margin-left: -85px;

            a {
                display: block;
                padding: 0 30px;
            }

            img {
                width: 100%;
            }
        }
    }


    .l-header-inner {
        display: flex;
        justify-content: space-between;
        padding: 0 40px;
        width: 100%;

        @media screen and (max-width: 1530px) {
            padding: 0 20px;
        }

        > div {
            display: table;
            height: 70px;

            > * {
                display: table-cell;
                vertical-align: middle;

                a:hover {
                    text-decoration: none;
                }
            }

            @include mq(large, max, true) {
                height: 60px;
            }
        }

        .e-main {

            h1 {
                width: 166px;

                @include mq(xlarge, max, true) {
                    width: 95px;
                }
            }

            .e-nav {
                @include font-Amiri-R;
                font-size: 0;
                padding-left: 60px;

                @media screen and (max-width: 1530px) {
                    padding-left: 50px;
                }
                @media screen and (max-width: 1430px) {
                    padding-left: 30px;
                }
                @include mq(xlarge, max, true) {
                    padding-left: 15px;
                }

                > ul {
                    font-size: 0;

                    > li {
                        display: inline-block;
                        @include css-lock(1.6, 1.6);

                        @include mq(xlarge, max, true) {
                            @include css-lock(1.4, 1.4);
                        }

                        &.e-login {
                            @include css-lock(1.4, 1.4);
                        }

                        > a {
                            color: $color-base;
                            display: inline-block;
                            padding: 22px 20px;

                            @media screen and (max-width: 1530px) {
                                padding: 22px 15px;
                            }
                            @media screen and (max-width: 1430px) {
                                padding: 22px 10px;
                            }
                            @include mq(xlarge, max, true) {
                                padding: 22px 8px;
                            }

                            span {
                                border-bottom: 2px solid rgba(255, 255, 255, 0);
                                transition: all 0.3s;
                            }
                        }

                        &:hover > a span,
                        & > a.current span {
                            border-bottom: 2px solid $color-base;
                        }

                        .e-nav-second {
                            visibility: hidden;
                            opacity: 0;
                            z-index: 1;

                            li {
                                font-size: 1.4rem;
                                display: block;

                                + li {
                                    margin-top: 1px;
                                }

                                a {
                                    display: inline-block;
                                    background-color: $color-green;
                                    color: $color-white;
                                    padding: 15px 0 15px 10px;
                                    background-image: url(../img/common/arrow-white-01.png);
                                    background-repeat: no-repeat;
                                    background-position: right 17px center;

                                    &:hover {
                                        color: rgba(255, 255, 255, 0.7)
                                    }
                                }
                            }
                        }

                        &.e-nav-single {

                            ul.e-nav-second {
                                position: absolute;
                                top: 60px;
                                transition: all .5s ease;

                                li {

                                    a {
                                        width: 270px;
                                        box-sizing: border-box;
                                    }
                                }
                            }

                            &:hover {

                                ul.e-nav-second {
                                    top: 70px;
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .e-sub {

            ul {
                font-size: 0;

                li {
                    display: inline-block;
                    font-size: 1.4rem;
                    line-height: 1;

                    a {
                        color: $color-white;
                        display: inline-block;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        @include font-mincho;
                    }

                    &.e-salon {

                        a {
                            padding-left: 20px;
                            padding-right: 20px;
                            border: 1px solid $color-green;
                            color: $color-green;
                            transition: all 0.3s;

                            &:hover {
                                background-color: $color-green-02;
                                color: $color-white;
                            }
                        }
                    }

                    &.e-member {
                        margin-left: 15px;

                        a {
                            padding-left: 30px;
                            padding-right: 30px;
                            border: 1px solid $color-green;
                            background-color: $color-green;
                            transition: all 0.3s;

                            &:hover {
                                border: 1px solid $color-green-02;
                                background-color: $color-green-02;
                            }
                        }
                    }

                    &.e-member-name {
                        color: $color-base;
                        @include font-mincho;
                    }
                }

                @media screen and (max-width: 1530px) {

                    li {

                        &.e-salon {

                            a {
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }

                        &.e-member {
                            margin-left: 10px;

                            a {
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }
                }

                @include mq(xlarge, max, true) {

                    li {

                        .drawer-close & {
                            word-break: keep-all;
                            @include css-lock(1.2, 1.2);

                            &.e-salon {

                                a {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }

                            &.e-member {
                                margin-left: 7px;

                                a {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.clone-nav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        transition: .3s;
        transform: translateY(-100%);
        background-color: $color-white;

        .l-header-inner {

            .e-main {

                h1 .bule {
                    display: inline;
                }
                h1 .white {
                    display: none;
                }

                .e-nav li {

                    a {
                        color: $color-base;
                    }

                    &:hover > a span {
                        border-bottom: 2px solid $color-base;
                    }
                    .e-nav-second {

                        li {

                            a {
                                background-color: $color-green;
                                color: $color-white;
                                background-image: url(../img/common/arrow-white-01.png);

                                &:hover {
                                    color: rgba(255, 255, 255, 0.7);
                                }
                            }
                        }
                    }
                }
            }

            .e-sub ul li {

                a {
                    color: $color-bule;
                    color: $color-white;
                }

                &.e-salon {

                    a {
                        border: 1px solid $color-green;
                        color: $color-green;

                        &:hover {
                            border: 1px solid $color-green-02;
                            background-color: $color-green-02;
                            color: $color-white;
                        }
                    }
                }

                &.e-member-name {
                    color: $color-base;
                }
            }
        }

        &.is-show {
            transform: translateY(0);
            height: 70px;

            @include mq(large, max, true) {
                height: 50px;
            }
        }
    }
}