@charset "UTF-8";

// ---------------------------
// Img
// ---------------------------

img.object-fit-img,
.object-fit-img-parent img {
  object-fit: cover;
  font-family: 'object-fit: cover;'
}



@include mq(large, max, true) { // タブレット横

    .img-100-l {
        width: 100%;
    }

    .img-70-l {
        width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@include mq(medium, max, true) { // タブレット縦

    .img-100-m {
        width: 100%;
    }

    .img-70-m {
        width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@include mq(small, max, true) { // スマートフォン

    .img-100-s {
        width: 100%;
    }

    .img-70-s {
        width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}



.m-salon-img {
    display: flex;

    li {
        margin-left: 20px;

        img {
            width: 120px;
            height: 90px;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}