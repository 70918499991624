@charset "UTF-8";

// ---------------------------
// Layout
// ---------------------------

.wrap {
    margin-left: auto;
    margin-right: auto;

    .wrap {
        padding-left: 0;
        padding-right: 0;
    }

    @include mq(xlarge, max, true) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include mq(large, max, true) {
        padding-left: 20px;
        padding-right: 20px;
    }

    &.wrap-full {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        .wrap {

            @include mq(xlarge, max, true) {
                padding-left: 40px;
                padding-right: 40px;
            }

            @include mq(large, max, true) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    &.wrap-1240 {
        max-width: 1240px;

        @include mq(xlarge, max, true) {
            max-width: 100%;
        }
    }

    &.wrap-1100 {
        max-width: 1100px;

        @include mq(xlarge, max, true) {
            max-width: 100%;
        }
    }

    &.wrap-850 {
        max-width: 760px;
    }

    &.wrap-760 {
        max-width: 760px;
    }

    &.wrap-90percent {
        max-width: 90%;

        @include mq(large, max, true) {
            max-width: 100%;
        }
    }

    &.wrap-space-none {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}



// https://coliss.com/articles/build-websites/operation/css/css-quirks-oddities-and-advanced-tips.html
// 基準値：1100px
.section { //180px
    padding: 16.36% 0;

    &.section-px-180 {
        padding: 180px 0;

        @include mq(medium, max, true) {
            padding: 120px 0;
        }
    }
    &.section-px-100-180 {
        padding: 100px 0 180px;;

        @include mq(medium, max, true) {
            padding: 60px 0 120px;
        }
    }
    &.section-px-100 {
        padding: 100px 0;

        @include mq(medium, max, true) {
            padding: 60px 0;
        }
    }

    @include mq(medium, max, true) {
        padding: 120px 0;
    }

    .section { //100px
        padding: 9.09% 0;

        @include mq(medium, max, true) {
            padding: 60px 0;
        }

        .section { //50px
            padding: 4.54% 0;

            @include mq(medium, max, true) {
                padding: 30px 0;
            }
        }
    }

    &.section-min { //100px 180px
        padding: 9.09% 0 16.36%;

        @include mq(medium, max, true) {
            padding: 60px 0 100px;
        }
    }

    &.section-min-02 { //180px 100px
        padding: 16.36% 0 9.90%;

        @include mq(medium, max, true) {
            padding: 100px 0 60px;
        }
    }

    &.section-xmin { //100px 100px
        padding: 9.09% 0;

        @include mq(medium, max, true) {
            padding: 60px 0;
        }
    }
}



.l-column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.l-column-wrap { flex-wrap: wrap;}
    &.l-column-reverse { flex-direction: row-reverse; }
    &.l-column-center { justify-content: center; }
    &.l-column-left { justify-content: flex-start; }
    &.l-column-top { align-items: flex-start !important; }
    &.l-column-margin {

        > * {
            margin-left: 25px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &.l-column-2 {

        > * {
            width: 50%;
        }

        &.pattern-01 {

            > * {
                width: calc((100% - 60px) / 2);
            }
        }

        @include mq(medium, max, true) {
            display: block;

            > * {
                width: 100%;
                margin-top: 0;
            }

            &.pattern-01 {
                display: flex;

                > * {
                    width: calc((100% - 60px) / 2);
                }
            }
        }

        @include mq(small, max, true) {

            &.pattern-01 {
                display: block;

                > * {
                    width: 100%;

                    + * {
                        margin-top: 60px;
                    }
                }
            }
        }
    }

    &.l-column-3 {

        > * {
            width: calc(100% / 3);
        }

        &.pattern-01 {

            > * {
                width: calc((100% - 51px) / 3);
            }
        }

        &.pattern-02 {
            flex-wrap: wrap;
            margin-top: calc( ((100% - (((100% / 3) - 20px) * 3)) / 2) * -1 );

            > * {
                width: calc((100% / 3) - 20px);
                margin-top: 0;
                text-align: center;
                margin-top: calc( (100% - (((100% / 3) - 20px) * 3)) / 2 );

                &:nth-child(3n+2):last-child {
                    margin-left: auto;
                    margin-right: calc( ((100% / 3) - 20px) + ((100% - (((100% / 3) - 20px) * 3)) / 2) );
                }
            }

            &.m-img-list {

                > * {
                    width: calc((100% / 3) - 40px);
                }
            }
        }

        &.pattern-03 {
            margin-left: -27px;

            > * {
                width: calc((100% - 81px) / 3);
                margin-left: 27px;
            }
        }

        @include mq(medium, max, true) {
            justify-content: flex-start;
            margin-top: -25px;

            > * {
                width: calc(100% / 2);
                margin-top: 25px;

                &:nth-child(2n+1) {
                    margin-left: 0;
                }
            }

            &.pattern-01 {

                > * {
                    width: calc((100% - 25px) / 2);
                    margin-left: 25px;

                    &:nth-child(2n+1) {
                        margin-left: 0;
                    }
                }
            }

            &.pattern-02 {
                justify-content: space-between;

                > * {
                    width: calc((100% / 2) - 20px);

                    &:nth-child(3n+2):last-child {
                        margin-right: inherit;
                        margin-left: inherit;
                    }

                    &.m-img-list {

                        > * {
                            width: calc((100% / 3) - 20px);
                        }
                    }
                }
            }

            &.pattern-03 {
                margin-left: -20px;

                > * {
                    width: calc((100% - 40px) / 2);
                    margin-left: 20px;
                }
            }
        }

        @include mq(small, max, true) {

            > * {
                width: 100%;
                margin-left: 0;
            }

            &.pattern-01,
            &.pattern-03 {
                margin-left: 0;

                > * {
                    width: 100%;
                    margin-left: 0;
                }
            }

            &.pattern-02 {

                > * {
                    width: 100% !important;
                }
            }
        }
    }

    &.l-column-4 {

        > * {
            width: 25%;
        }

        &.pattern-01 {

            > * {
                width: calc((100% / 4) - 20px);
            }
        }

        &.pattern-02 {
            margin-top: calc( (((100% - ((25% - 10px) * 4)) / 3) + 10px) * -1 );

            > * {
                width: calc(25% - 10px);
                margin-top: calc( ((100% - ((25% - 10px) * 4)) / 3) + 10px );

                &:nth-child(4n+2):last-child {
                    margin-right: calc( ((25% - 10px) + ((100% - ((25% - 10px) * 4)) / 3)) * 2 );
                }

                &:nth-child(4n+3):last-child {
                    margin-right: calc( (25% - 10px) + ((100% - ((25% - 10px) * 4)) / 3) );
                }
            }
        }

        &.pattern-03 {
            //margin-top: calc( (((100% - ((25% - 30px) * 4)) / 3)) * -1 );

            > * {
                width: calc(25% - 30px);
                margin-top: calc( ((100% - ((25% - 30px) * 4)) / 3) );

                &:nth-child(4n+2):last-child {
                    margin-right: calc( ((25% - 30px) + ((100% - ((25% - 30px) * 4)) / 3)) * 2 );
                }

                &:nth-child(4n+3):last-child {
                    margin-right: calc( (25% - 30px) + ((100% - ((25% - 30px) * 4)) / 3) );
                }
            }
        }

        @include mq(xlarge, max, true) {
            &.pattern-03 {
                flex-wrap: wrap;
                margin-top: 20px;

                > * {
                    width: calc(50% - 10px);
                    margin-top: 20px;

                    &:nth-child(4n+2):last-child {
                        margin-right: 0;
                    }

                    &:nth-child(4n+3):last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        @include mq(medium, max, true) {

            > * {
                width: 50%;
            }

            &.pattern-01 {

                > * {
                    width: calc((100% / 2) - 20px);
                }
            }

            &.pattern-02 {
                flex-wrap: wrap;
                margin-top: -40px;

                > * {
                    width: calc(50% - 10px);
                    margin-top: 40px;

                    &:nth-child(4n+2):last-child {
                        margin-right: 0;
                    }

                    &:nth-child(4n+3):last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        @include mq(small, max, true) {

            > * {
                width: 100%;
            }

            &.pattern-01,
            &.pattern-02,
            &.pattern-03 {

                > * {
                    width: 100%;
                }
            }
        }
    }

    &.l-column-5 {

        > * {
            width: 20%;
        }

        &.pattern-01 {

            > * {
                width: calc((100% / 5) - 20px);
            }
        }
    }
}



.m-img-layout-01 {

    > *,
    .e-sub img {
        height: 580px;
    }

    &.m-img-layout-01-min {

        > *,
        .e-sub img {
            height: 480px;
        }
    }

    .e-main {
        background-color: $color-gray;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > div {
            width: 550px;
            padding-right: 100px;
        }

        &.pattern-01 {
            background-color: $color-white;
            justify-content: flex-start;

            > div {
                padding-left: 100px;
                padding-right: 0;
            }
        }

        &.pattern-02 {
            justify-content: flex-start;

            > div {
                padding-left: 100px;
                padding-right: 0;
            }
        }
    }

    @include mq(xlarge, max, true) {

        .e-main {

            > div {
                width: 100%;
                padding-left: 40px;
                padding-right: 40px;
            }

            &.pattern-01 {

                > div {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }

            &.pattern-02 {

                > div {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }
        }
    }

    @include mq(large, max, true) {

        .e-main {

            > div {
                padding-left: 20px;
                padding-right: 20px;
            }

            &.pattern-01 {

                > div {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            &.pattern-02 {

                > div {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }

    @include mq(medium, max, true) {

        > * {
            height: auto;
        }
        .e-sub img {
            height: 430px;
        }

        &.m-img-layout-01-min {

            > * {
                height: auto;
            }
            .e-sub img {
                height: 430px;
            }
        }

        .e-main {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    @include mq(small, max, true) {

        .e-sub img {
            height: 320px;
        }

        &.m-img-layout-01-min {
            .e-sub img {
                height: 320px;
            }
        }

        .e-main {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
}

.m-img-layout-02,
.m-img-layout-03 {

    .e-main {
        display: flex;
        align-items: center;
    }
}
.m-img-layout-02 {

    .e-main {

        > div {
            padding-right: 100px;

            @include mq(large, max, true) {
                padding-right: 50px;
            }

            @include mq(small, max, true) {
                padding-right: 0;
                margin-bottom: 40px;
            }
        }
    }

    @include mq(medium, max, true) {

        .e-main {

            > div {
                width: 100%;
                padding-right: 0;
            }
        }

        .e-sub {
            margin-top: 30px;
        }
    }
}
.m-img-layout-03 {

    .e-main {

        > div {
            padding-left: 100px;

            @include mq(large, max, true) {
                padding-left: 50px;
            }

            @include mq(small, max, true) {
                padding-left: 0;
                margin-bottom: 40px;
            }
        }
    }

    @include mq(medium, max, true) {

        .e-main {

            > div {
                width: 100%;
                padding-left: 0;
            }
        }

        .e-sub {
            margin-top: 30px;
        }
    }
}

.m-img-layout-04 {

    > *,
    .e-sub img {
        height: 710px;
    }

    .e-main {
        background-color: $color-green-03;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        > div {
            width: 550px;
        }
    }

    @include mq(xlarge, max, true) {

        .e-main {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @include mq(large, max, true) {

        .e-main {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @include mq(small, max, true) {

        > *,
        .e-sub img {
            height: 320px;
        }

        .e-main {
            height: auto;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
}

.m-img-layout-05 {

    & + .m-img-layout-05 {
        margin-top: 50px;
    }

    .e-main {
        width: calc(100% - 340px);
    }
    .e-sub {
        width: 290px;
    }

    @include mq(medium, max, true) {

        .e-main {
            width: 100%;
        }

        .e-sub {
            width: auto;
            max-width: 100%;
            margin-top: 30px;
        }
    }

    @include mq(small, max, true) {
    }
}

.m-img-layout-06 {

    .e-main {
        padding: 50px 100px 0 0;

        @include mq(large, max, true) {
            padding: 0 100px 0 0;
        }

        @include mq(medium, max, true) {
            padding: 50px 0 0 0;
            text-align: center;
        }

        @include mq(small, max, true) {
            padding: 40px 0 0 0;
        }
    }
}

.m-img-layout-07 {

    li {
        margin: 0 20px;

        img {
            width: 270px;
        }
    }

    @include mq(small, max, true) {

        li {
            width: 100%;
            margin: 0;

            + li {
                margin-top: 20px;
            }

            img {
                width: 100%;
            }
        }
    }
}

.m-img-layout-08 {

    .e-main {
        width: calc(100% - 400px);
        padding-right: 90px;
    }

    .e-sub {
        width: 400px;

        img {
            width: 100%;
        }

        .e-text {
            display: inline-block;
            margin-top: 10px;
            line-height: 1.5;
        }
    }

    &.l-column-reverse {

        .e-main {
            padding-left: 90px;
            padding-right: 0;
        }
    }

    @include mq(medium, max, true) {

        .e-main {
            width: 100%;
            padding-right: 0;
        }

        .e-sub {
            width: 100%;
            display: inline-block;
            text-align: center;
            margin-top: 30px;

            img {
                width: inherit;
                max-width: 100%;
            }
        }

        &.l-column-reverse {

            .e-main {
                padding-left: 0;
            }
        }
    }
}

.m-img-layout-09 {

    .e-sub {
        width: 48.18181%;
        padding-right: 4.54545%;

        img {
            width: 100%;
        }
    }

    .e-main {
        width: calc(100% - 48.18181%);

        ul {
            margin-bottom: 30px;
            display: flex;

            li {
                width: 35%;
                margin-left: 20px;

                img {
                    width: 100%;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    @include mq(medium, max, true) {
        display: flex !important;
    }

    @include mq(small, max, true) {
        display: block !important;

        .e-sub {
            width: 100%;
            padding-right: 0;
        }

        .e-main {
            width: 100%;
            margin-top: 30px;

            ul {
                justify-content: space-between;

                li {
                    margin-left: 0;
                    width: 45%;
                }
            }
        }
    }
}



.m-inlineblock-layout {

    > * {
        display: inline-block;
        margin: 0 20px;

        @include mq(small, max, true) {
            display: block;
            margin: 0;
        }
    }
}