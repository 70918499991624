@charset "UTF-8";

// ---------------------------
// Keyvisual
// ---------------------------

.l-keyvisual-Underlayer {
    width: 100%;
    height: 340px;
    display: table;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @include font-LibreBaskerville-R;
    margin-top: 70px;

    @include mq(xlarge, max, true) {
        margin-top: 70px;
        padding-left: 20px;
        padding-right: 20px;
    }

    @include mq(large, max, true) {
        margin-top: 50px;
        height: 280px;
    }

    > div {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        @include css-lock(3.6, 3.6);
        color: $color-white;
        line-height: 1.5;

        &.ja {
            @include css-lock(3.2, 3.2);
        }

        span {
            display: block;
            @include css-lock(1.8, 1.8);
        }

        @include mq(small, max, true) {
            font-size: 3.4rem;

            &.ja {
                font-size: 3.0rem;
            }
        }
    }

    &.lp {
        background-image: url(../img/lp/bg-keyvisual.jpg);
        height: 500px;

        dl {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            color: $color-white;
            line-height: 1.5;

            dt {
                @include css-lock(2.0, 2.0);

                span {
                    margin-top: 10px;
                    display: block;
                    @include css-lock(4.0, 3.0);
                }
            }

            dd {
                margin-top: 40px;
            }
        }
    }

    &.aboutus {
        background-image: url(../img/aboutus/bg-keyvisual.jpg);
    }

    &.quality {
        background-image: url(../img/aboutus/quality/bg-keyvisual.jpg);
    }

    &.speciality {
        background-image: url(../img/aboutus/speciality/bg-keyvisual.jpg);
    }

    &.products,
    &.error404 {
        background-image: url(../img/products/bg-keyvisual.jpg);
    }

    &.select{
        background-image: url(../img/products/select/bg-keyvisual.jpg);
    }

    &.affiliation {
        background-image: url(../img/affiliation/bg-keyvisual.jpg);
    }

    &.agency {
        background-image: url(../img/affiliation/agency/bg-keyvisual.jpg);
    }

    &.login {
        background-image: url(../img/login/bg-keyvisual.jpg);
    }

    &.company {
        background-image: url(../img/company/bg-keyvisual.jpg);
    }

    &.policy {
        background-image: url(../img/policy/bg-keyvisual.jpg);
    }

    &.column {
        background-image: url(../img/column/bg-keyvisual.jpg);
    }

    &.salon {
        background-image: url(../img/salon/bg-keyvisual.jpg);
    }

    &.trialtreatment {
        background-image: url(../img/salon/trialtreatment/bg-keyvisual.jpg);
    }

    &.news {
        background-image: url(../img/news/bg-keyvisual.jpg);
    }

    &.contact {
        background-image: url(../img/contact/bg-keyvisual.jpg);
    }

    &.faq {
        background-image: url(../img/faq/bg-keyvisual.jpg);
    }

    &.sitemap {
        background-image: url(../img/sitemap/bg-keyvisual.jpg);
    }
}

.l-keyvisual-home {
    background-image: url(../img/home/keyvisual.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: table;
    animation: fadein-bg-img 3s linear;
    background-position: center 0;

    > div {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: $color-white;
        @include font-mincho;
        animation: fadein-bg-color 3s linear;
        background-color: rgba(255, 255, 255, 0);

        dd {
            @include css-lock(2.8, 2.0);
            margin-top: 20px;
        }
    }

    @include mq(small, max, true) {

        > div {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}
@keyframes fadein-bg-img {
    0% {
        background-position: center 20px;
    }
    100% {
        background-position: center 0;
    }
}
@keyframes fadein-bg-color {
    0% {
        background-color: rgba(255, 255, 255, 1);
    }
    100% {
        background-color: rgba(255, 255, 255, 0);
    }
}