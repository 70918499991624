@charset "UTF-8";

// ---------------------------
// Form
// http://getskeleton.com/
// ---------------------------

input[type="text"] {
    line-height: 1;
}
input[type="submit"] {
    border: none;
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    width: 100% !important;
    padding: 15px 20px !important; /* The 6px vertically centers text on FF, ignored by Webkit */
    background-color: #f7f7f7 !important;
    border: none !important;
    box-shadow: none !important;
    box-sizing: border-box !important;
    font-size: 1.4rem !important;
    @include font-gothic;

    &.inline {
        width: inherit;
    }
    &.w-300 {
        width: 300px;

        @include mq(xsmall, max, true) {
            width: 100%;
        }
    }
    &.bg-white {
        background-color: $color-white;
    }
}
select::-ms-expand {
    display: none;
}

@media screen and (max-width: 767px) {
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="password"],
    textarea,
    select {
      padding: 15px 15px; /* The 6px vertically centers text on FF, ignored by Webkit */
    }
}

/* Removes awkward default styles on some inputs for iOS */
textarea {
    min-height: 100px;
}
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
    //border: 1px solid $color-base;
    outline: 0;
}

label,
legend {
    display: block;
    margin-bottom: .5rem;
    font-weight: 600;
}

fieldset {
    padding: 0;
    border-width: 0;
}

select {
    background-image: url(../img/common/arrow-green-02.png);
    background-repeat: no-repeat;
    background-position: right 10px center;
}

input[type="checkbox"],
input[type="radio"] {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
    display: none;

    + span {
        padding-left: 30px;
        position: relative;
        font-weight: normal;


        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border: 1px solid $color-green;
            margin-top: -3px;
        }
    }

    &:checked + span::before {
        border: 1px solid $color-green;
    }

    &:checked + span::after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 6px;
        width: 8px;
        height: 11px;
        transform: rotate(40deg);
        border-bottom: 2px solid $color-green;
        border-right: 2px solid $color-green;
        margin-top: -3px;
    }
}
input[type="radio"] {

    + span {
        padding-left: 30px;

        &::before {
            background-color: $color-white;
            border: 1px solid $color-green;
            border-radius: 50%;
        }
    }

    &:checked + span::before {
        background-color: $color-white;
        border: 1px solid $color-green;
    }

    &:checked + span::after {
        top: 6px;
        left: 6px;
        width: 10px;
        height: 10px;
        background-color: $color-green;
        border-radius: 50%;
        border: none;
    }
}

label > .label-body {
    display: inline-block;
    margin-left: .5rem;
    font-weight: normal;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #c8c8ca;
    font-weight: normal;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #c8c8ca;
    font-weight: normal;
}
input:-ms-input-placeholder,
textarea::-ms-input-placeholder {
    color: #c8c8ca;
    font-weight: normal;
}



.m-form-btn {
    display: flex;
    justify-content: center;

    > * {

        > * {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    @include mq(small, max, true) {

        > * {

            > * {
                margin-left: 5px;
                margin-right: 5px;
                font-size: 1.2rem;
                width: 155px;
            }
        }
    }
}
.mw_wp_form .m-msg-error {
    font-size: 1.5rem !important;
    font-weight: bold;
}





// ---------------------------
// SATORI フォーム調整
// ---------------------------
.satori__input_group,
.satori__horiz_group,
#satori__privacy_policy_agreement_wrapper {
    border-top: 1px solid #9ea2a2 !important;
    font-size: 14px !important;

    .satori__input_group,
    #satori__privacy_policy_agreement_wrapper & {
        border-top: none !important;
    }
}

#satori__privacy_policy_agreement_wrapper {
    padding-top: 40px;

    a {
        color: #5d7975 !important;
        text-decoration: underline !important;

        &:hover {
            text-decoration: none !important;
        }
    }

    @include mq(medium, max, true) {
        padding-top: 20px;
    }
}

.satori__input_group {
    padding: 40px !important;

    @include mq(medium, max, true) {
        padding: 20px 5px !important;
    }

    input[type="checkbox"],
    input[type="radio"] {
        height: 20px !important;
        width: 20px !important;
        display: inline-block !important;
        vertical-align: middle;
    }

    input[type="checkbox"] {
        -webkit-appearance: checkbox !important;
        -moz-appearance: checkbox !important;
        appearance: checkbox !important;
    }

    input[type="radio"] {
        -webkit-appearance: radio !important;
        -moz-appearance: radio !important;
        appearance: radio !important;
    }

    label, legend {
        display: inline-block !important;
    }

    label:first-child {
        display: block !important;
    }
}

.satori__submit_group .satori__btn {
    width: 300px !important;
    line-height: 1 !important;
    padding: 22px 0 18px !important;
    font-size: 1.6rem !important;
    font-family: 'Amiri-R', "Hiragino Mincho ProN", 游明朝, HGS明朝E, serif !important;
    border-radius: 0 !important;

    @include mq(medium, max, true) {
        width: 155px !important;
    }
}
