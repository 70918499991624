@charset "UTF-8";

// ---------------------------
// Footer
// ---------------------------

.l-footer {
    background-color: #848787;
    color: $color-white;
    text-align: center;
    position: relative;

    .l-footer-inner {
        padding: 5.36% 0 3.63%;

        @include mq(medium, max, true) {
            padding: 60px 0 40px;
        }
    }

    a {
        color: $color-white;

        &:hover {
            text-decoration: none;
        }
    }

    .e-logo {
        width: 218px;
        display: inline-block;

        @include mq(medium, max, true) {
            width: 180px;
        }
    }

    .e-nav {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        @include css-lock(1.6, 1.6);
        @include font-Amiri-R;
        margin-top: 60px;

        li {
            margin: 0 15px;

            span {
                border-bottom: 2px solid rgba(255, 255, 255, 0);
                transition: all 0.3s;
            }

            &:hover > a span,
            & > a.current span {
                border-bottom: 2px solid $color-white;
            }
        }

        @include mq(large, max, true) {

            li {
                margin: 0 10px;
            }
        }

        @include mq(medium, max, true) {
            justify-content: flex-start;
            border-top: 1px solid $color-white;

            li {
                width: 50%;
                margin: 0;
                border-bottom: 1px solid $color-white;

                &:nth-child(odd) {
                    border-right: 1px solid $color-white;
                }

                span {
                    border-bottom: none;
                }

                a {
                    display: block;
                    padding: 20px 0;
                    line-height: 1;
                }

                &:hover > a span,
                & > a.current span {
                    border-bottom: none;
                }
            }
        }
    }

    .e-nav-02 {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        li {
            line-height: 1;

            a {
                color: $color-white;
                display: inline-block;
                padding-top: 10px;
                padding-bottom: 10px;
                @include font-mincho;
            }

            &.e-salon {

                a {
                    padding-left: 20px;
                    padding-right: 20px;
                    border: 1px solid $color-white;
                    color: $color-white;
                    transition: all 0.3s;

                    &:hover {
                        background-color: $color-green-02;
                        border: 1px solid $color-green-02;
                        color: $color-white;
                    }
                }
            }

            &.e-member {
                margin-left: 15px;

                a {
                    padding-left: 40px;
                    padding-right: 40px;
                    border: 1px solid $color-white;
                    background-color: $color-white;
                    color: $color-green;
                    transition: all 0.3s;

                    @include mq(small, max, true) {
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    &:hover {
                        border: 1px solid $color-green-02;
                        background-color: $color-green-02;
                        color: $color-white;
                    }
                }
            }

            &.e-member-name {
                color: $color-white;
            }
        }
    }

    .e-nav-sns {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        li {
            height: 20px;
            margin: 0 15px;

            img {
                height: 100%;
                vertical-align: top;
            }

            a:hover img {
                opacity: 0.5;
            }
        }
    }

    .e-nav-03 {
        display: flex;
        justify-content: space-between;
        @include font-mincho;
        @include css-lock(1.2, 1.2);
        padding: 15px 40px;
        border-top: 1px solid $color-white;


        ul {
            display: flex;
            flex-wrap: wrap;

            li {

                &::before {
                    content: '|';
                    display: inline-block;
                    margin: 0 20px;
                }

                &:first-child::before {
                    display: none;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }

        p {
            @include font-Amiri-R;
        }

        @include mq(medium, max, true) {
            display: block;
            padding: 40px 0 15px;

            ul {

                li {
                    width: 50%;
                    margin-bottom: 20px;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .m-page-top {
        display: block;
        position: fixed;
    	right: 40px;
    	bottom: 20px;
        transition: all 0.25s ease-in-out 0.0s;
    	z-index: 10000;

        &.inactive {
        	bottom: -50px;
        }

        &.stop {
            position: absolute;
            right: 40px;
            top: -60px;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border: 1px solid $color-green;

            img {
                transition: 0.3s;
                position: relative;
                top: 0;
            }
        }

        a:hover img {
            top: -3px;
        }

        @include mq(large, max, true) {
            right: 20px;

            &.stop {
                right: 20px;
            }
        }
    }
}