@charset "UTF-8";

// ---------------------------
// Table
// ---------------------------

.m-table-01 {
    width: 100%;
    border-top: 1px solid $color-gray-02;

    tbody {

        tr {
        }

        th {
            width: 250px;
            border-bottom: 1px solid $color-gray-02;
            border-right: 1px solid $color-gray-02;
            padding: 40px;
            text-align: left;
            vertical-align: middle;
            font-weight: bold;

            span {
                color: $color-red;
                margin-left: 10px;
                font-weight: bold;
            }
        }

        td {
            border-bottom: 1px solid $color-gray-02;
            padding: 40px;
            vertical-align: middle;
        }
    }

    &.pattern-01 {

        tbody {

            th {
                width: 380px;
            }
        }
    }

    &.pattern-02 {

        tbody {

            th {
                width: 220px;
            }
        }
    }

    @include mq(medium, max, true) {
        display: block;

        tbody, tr, th, td {
            display: block;
        }

        tbody {

            tr {
            }

            th {
                width: 100%;
                border-right: 0;
                padding: 20px 10px;
            }

            td {
                padding: 20px 10px;
            }
        }

        &.pattern-01,
        &.pattern-02 {

            tbody {

                th {
                    width: 100%;
                }

                td {
                    padding-bottom: 50px;
                }
            }
        }
    }
}



.m-table-02 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;

    th {
        text-align: right;
        padding: 0 20px 0 0;
    }

    td {
        padding: 10px 0;
    }

    @include mq(small, max, true) {

        th, td {
            display: block;
            text-align: left;
        }
    }
}



.m-table-03 {
    width: 100%;
    line-height: 1.5;

    thead {

        tr {
            background-color: $color-base;
            color: $color-white;
        }

        th {
            text-align: left;
            padding: 10px 20px;

            &.e-th-01 { width: 70%; }
            &.e-th-02 { width: 15%; }
            &.e-th-03 { width: 15%; }
        }

        @include mq(small, max, true) {
            display: none;
        }
    }

    tbody {

        tr {

            &:nth-child(even) {
                background-color: $color-gray;
            }
        }

        th {
            vertical-align: middle;
            text-align: left;
            padding: 20px;
        }

        td {
            vertical-align: middle;
            padding: 20px;
        }

        @include mq(small, max, true) {

            th, td {
                display: block;
                width: 100%;
                padding: 10px;
            }
        }
    }
}



.m-table-04 {
    width: 100%;
    border-left: 1px solid $color-gray-02;
    border-top: 1px solid $color-gray-02;
    margin-top: 40px;

    thead {

        tr {
            background-color: #f8f7cd;
        }

        th {
            text-align: center;
            padding: 20px 40px;
            font-weight: bold;
            border-right: 1px solid $color-gray-02;
            border-bottom: 1px solid $color-gray-02;

            &.e-th-01 { width: 25%; }
            &.e-th-02 { width: 50%; }
            &.e-th-03 { width: 25%; }
        }
        @include mq(medium, max, true) {
            th {
                padding: 20px;
            }
        }
        @include mq(small, max, true) {
            display: none;
        }
    }

    tbody {

        tr {
        }

        th {
            vertical-align: middle;
            text-align: left;
            padding: 20px 40px;
            background-color: $color-gray;
            border-right: 1px solid $color-gray-02;
            border-bottom: 1px solid $color-gray-02;
        }

        td {
            vertical-align: middle;
            padding: 20px 40px;
            border-right: 1px solid $color-gray-02;
            border-bottom: 1px solid $color-gray-02;
        }
        @include mq(medium, max, true) {
            th, td {
                padding: 20px 15px;
            }
        }
        @include mq(small, max, true) {

            th, td {
                display: block;
                width: 100%;
                padding: 10px;
            }
        }
    }
}