@charset "UTF-8";

@charset "UTF-8";

// ---------------------------
// http://www.nxworld.net/tips/sass-media-queries-mixin.html
// ---------------------------

// 【PCファースト】
//    .elements {
//        1481px以上
//
//        @include mq(xxxlarge, max, true) {
//            1480px 以下
//        }
//        @include mq(xxlarge, max, true) {
//            1366px 以下
//        }
//        @include mq(xlarge, max, true) {
//            1280px 以下
//        }
//        @include mq(large, max, true) {
//            1024px 以下
//        }
//        @include mq(medium, max, true) {
//             768px 以下
//        }
//        @include mq(small, max, true) {
//             479px 以下
//        }
//        @include mq(xsmall, max, true) {
//             359px 以下
//        }
//    }

// 【SPファースト】
//    .elements {
//        0px以上
//
//        @include mq(xsmall) {
//            360px 以上
//        }
//        @include mq(small) {
//            480px 以上
//        }
//        @include mq(medium) {
//            769px 以上
//        }
//        @include mq(large) {
//            1025px 以上
//        }
//        @include mq(xlarge) {
//            1281px 以上
//        }
//        @include mq(xxlarge) {
//            1367px 以上
//        }
//        @include mq(xxxlarge) {
//            1481px 以上
//        }
//    }

$mq-breakpoints: (
    'xsmall': 360px,
    'small' : 480px,
    'medium': 769px,
    'large' : 1025px,
    'xlarge': 1281px,
    //'xlarge': 1181px,
    'xxlarge': 1367px,
    'xxxlarge': 1481px,
);

@mixin mq($breakpoint: xsmall, $rule: min, $subtraction: false) {
	$breakpoint: map_get($mq-breakpoints, $breakpoint);
	@if $rule == max and $subtraction {
		$breakpoint: $breakpoint - 1;
	}
	@media screen and (#{$rule}-width: $breakpoint) {
		@content;
	}
}