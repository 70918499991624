@charset "UTF-8";

// ---------------------------
// Original
// ---------------------------

.m-blog-style {

    > * {
        margin-top: 40px;

        &:first-child  {
            margin-top: 0;
        }
    }

    &.min {

        > * {
            margin-top: 20px;

            &:first-child  {
                margin-top: 0;
            }
        }
    }

    h2 {
        @include font-Amiri-R;
        font-weight: bold;
        font-size: 2.2rem;
        margin-top: 80px;
    }

    h3 {
        @include font-Amiri-R;
        font-weight: bold;
        font-size: 1.8rem;
    }

    ol,ul {
        margin-left: 1em;

        li {
            line-height: 1.5;

            + li {
                margin-top: 15px;
            }
        }

        ol,ul {
            margin-left: 0;
            margin-top: 15px;
        }
    }

    ul {
        list-style: disc;

        &.e-none {
            list-style: none;
        }
    }

    ol.e-none {
        list-style: none;

        li {
            text-indent: -2.8em;
            padding-left: 2.8em;
        }

        ol.e-none {

            li {
                text-indent: -1.5em;
                padding-left: 1.5em;
            }
        }
    }

    dl {

        dt {
            font-weight: bold;
        }

        dd {
            text-indent: 1em;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    blockquote {
        background-color: $color-gray;
        padding: 40px;

        @include mq(small, max, true) {
            padding: 30px 40px;

            &::before {
                font-size: 6.0rem;
                left: 10px;
                top: 10px;
            }
        }
    }

    .single-products & {

        h4 {
            font-weight: bold;
            font-size: 2.2rem;
        }
    }
}



.m-map-iframe {
    width: 600px;
    height: 360px;
    margin-top: 10px;

    @include mq(medium, max, true) {
        width: 100%;
    }
}

.m-map-iframe-02 {
    width: 100%;
    height: 400px;

    @include mq(medium, max, true) {
    }
}



.youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}
.youtube iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
}



#m-errormsg-all {
    color: #B70000;
    background-color: #f7e8e8;
    padding: 7px 20px 5px;
    font-weight: bold;
    text-align: center;
    line-height: 1.5;

    @include mq(small, max, true) {
        padding: 10px 10px 8px;
    }
}
.m-form-style {

    .mw_wp_form .error,
    .m-text-error,
    .mauticform-errormsg {
        color: #B70000;
        background-color: #f7e8e8;
        padding: 5px 10px 3px;
        font-weight: bold;
        display: block;
        line-height: 1.5;
    }

    input.error {
        background: #fbcdd5;
    }
    input + .m-text-error,
    input + .mauticform-errormsg,
    input + .error {
        display: block;
        text-align: left;
    }

    .e-submit {
        text-align: center;
        margin-top: 60px;

        dt {
            display: inline-block;
        }

        dd {
            margin-top: 10px;
        }
    }
}



.m-cate-01 {
    display: flex;
    justify-content: center;
    @include font-Amiri-R;
    line-height: 1;
    margin-bottom: 20px;

    .e-cate-01 {
        @include css-lock(2.2, 2.2);
        font-weight: bold;
    }

    .e-cate-02 {
        border: 1px solid $color-gray-02;
        padding: 5px 10px 0;
        margin-left: 20px;
    }

    &.pattern-01 {
        justify-content: flex-start;
        margin-bottom: 0;
        margin-top: 20px;

        .e-cate-01 {
            @include css-lock(1.2, 1.2);
            margin-top: 5px;
        }

        .e-cate-02 {
            @include css-lock(1.0, 1.0);
            margin-left: 10px;
        }
    }
}



.e-condition {
    display: flex;

    li {
        width: 147px;
        background-color: #f8f7cd;
        text-align: center;
        @include font-Amiri-R;
        line-height: 1;
        margin-left: 10px;
        padding: 10px 0 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
            margin-left: 0;
        }

        span {
            display: inline;
        }
    }
}



.m-details-01 {

    .e-sub {

        .e-img {
            background-color: $color-gray;
            padding: 85px 0;
            text-align: center;

            img {
                width: 70%;
            }

            @include mq(small, max, true) {
                padding: 50px 0;
            }
        }

        .e-awards {
            display: flex;
            margin-top: 20px;

            dt {
                width: 140px;
                padding-right: 20px;

                img {
                    width: 100%;
                }
            }

            dd {
                width: calc(100% - 140px);

                .e-heading {
                    @include font-Amiri-R;
                    @include css-lock(2.0, 1.6);
                    line-height: 1.5;
                    font-weight: bold;
                    margin-top: 20px;
                }

                .e-content {
                    margin-top: 10px;
                    line-height:  1.7;

                    * + * {
                        margin-top: 20px;
                    }

                    a {
                        color: $color-base;
                        text-decoration: underline;
                    }

                    a:hover {
                        text-decoration: none;
                    }
                }
            }

            @include mq(small, max, true) {
                display: block;

                dt {
                    padding-right: 0;
                    margin-left: auto;
                    margin-right: auto;
                }

                dd {
                    width: 100%;
                }
            }
        }
    }

    .e-main {
        padding-left: 100px;

        .e-time-zone {
            display: flex;
            margin-bottom: 20px;

            li {
                margin-left: 15px;

                &:first-child {
                    margin-left: 0;
                }

                img {
                    width: 30px;
                }
            }
        }

        .e-text-01 {

            h3 {
                @include font-Amiri-R;
                @include css-lock(1.6, 1.6);
                font-weight: bold;
                padding-top: 10px;
                margin-bottom: 30px;
            }

            ul {
                margin-top: 20px;
                list-style: disc;
                margin-left: 1em;

                li {
                    line-height: 1.5;
                    margin-bottom: 5px;
                }
            }
        }

        .e-list {
            border-top: 1px solid $color-gray-02;
            margin-top: 40px;

            dl {
                display: table;
                width: 100%;
                border-bottom: 1px solid $color-gray-02;
                padding: 15px 0;

                > * {
                    display: table-cell;
                }

                dt {
                    width: 100px;
                }
            }
        }

        .e-text-02 {
            @include css-lock(1.0, 1.0);
            margin-top: 20px;
        }

        .e-btn {
            display: flex;
            justify-content: space-between;

            > li {
                width: calc((100% / 2) - 10px);

                a {
                    width: 100%;
                }
            }

            @include mq(small, max, true) {
                display: block;

                > li {
                    width: 100%;

                    + li {
                        margin-top: 20px;
                    }
                }
            }
        }

        @include mq(xlarge, max, true) {
            padding-left: 50px;
        }

        @include mq(medium, max, true) {
            padding-left: 0;
            margin-top: 40px;
        }
    }
}



.m-line-box {
    text-align: center;
    border: 1px solid $color-gray-02;
    padding: 32px 30px 40px;

    &.pattern-01 {
        padding: 40px 40px 40px;
    }

    .e-title {
        @include font-Amiri-R;
        @include css-lock(1.8, 1.8);
        display: inline-block;
        padding: 0 30px;
        position: relative;
        top: -45px;
        line-height: 1;
        font-weight: bold;
    }

    @include mq(small, max, true) {
        padding: 20px;

        &.pattern-01 {
            padding: 20px;
        }

        .e-title {
            top: -30px;
        }
    }
}



.m-line-box-02 {
    text-align: center;
    border: 1px solid $color-gray-02;
    padding: 32px 25px 25px;

    .e-title {
        @include font-Amiri-R;
        @include css-lock(1.8, 1.8);
        display: inline-block;
        padding: 0 30px;
        position: relative;
        top: -45px;
        line-height: 1;
        font-weight: bold;
    }

    .e-img {

        img {
            width: 100%;
        }
    }

    .e-text {
        text-align: left;
        margin-top: 20px;

        dt {
            @include font-Amiri-R;
            @include css-lock(1.6, 1.6);
            font-weight: bold;
        }

        dd {
            @include css-lock(1.4, 1.4);
            line-height: 1.5;
            text-indent: -1em;
            padding-left: 1em;
        }
    }

    @include mq(small, max, true) {
        padding: 20px;

        .e-title {
            top: -30px;
        }
    }
}



.m-flow {
    display: flex;
    justify-content: space-between;

    dl {
        width: 32.27272%;
        background-image: url(../img/affiliation/arrow-01.png);
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 6.81818%;

        &:first-child {
            width: 25.45454%;
            padding-left: 0;
            background-image: none;
        }

        dt {
            @include font-Amiri-B;
            @include css-lock(2.2, 2.2);
            background-color: #f8f7cd;
            color: $color-green-02;
            text-align: center;
            line-height: 1;
            padding: 10px 0 5px;
            margin-bottom: 20px;

            span {
                @include css-lock(4.0, 4.0);
                vertical-align: text-top;
                margin-left: 10px;
            }
        }

        dd {

            h4 {
                @include font-Amiri-R;
                @include css-lock(1.6, 1.5);
                font-weight: bold;
                line-height: 1.5;
                height: 55px;
            }
        }
    }

    @include mq(small, max, true) {
        display: block;

        dl {
            width: 100%;
            background-image: none;
            padding-left: 0;

            &:first-child {
                width: 100%;
            }

            + dl {
                margin-top: 20px;
            }
        }
    }
}

.m-flow-02 {
    display: flex;
    flex-wrap: wrap;
    margin-top: -30px;

    li {
        width: 26.36363%;
        background-image: url(../img/affiliation/arrow-01.png);
        background-repeat: no-repeat;
        background-position: left 24px center;
        background-size: 15px auto;
        padding-left: 5.45454%;
        margin-top: 30px;

        &:nth-child(4n+1) {
            width: 20.90911%;
            padding-left: 0;
            background-image: none;
        }

        .e-step {

            > span {
                @include font-Amiri-B;
                background-color: $color-gray;
                position: relative;
                z-index: 10;
                display: inline-block;
                padding-right: 10px;
                color: $color-green-02;

                span {
                    @include css-lock(2.0, 2.0);
                }
            }

            &::before {
                content: '';
                position: relative;
                top: 23px;
                display: block;
                width: 100%;
                height: 1px;
                border-top: 1px solid $color-gray-02;
            }
        }

        .e-img {

            img {
                width: 100%;
            }
        }

        .e-text {
            margin-top: 10px;

            dt {
                @include font-Amiri-R;
                @include css-lock(1.6, 1.6);
                font-weight: bold;
                line-height: 1.5;
                margin-bottom: 5px;
            }

            dd {
                line-height: 1.5;
            }
        }
    }

    &.pattern-01 {
        //justify-content: center;
        margin-top: 0;

        li {
            width: 39.54545%;
            background-position: left 21.83% center;
            padding-left: 18.63636%;
            margin-top: 60px;

            &:nth-child(4n+1) {
                width: 39.54545%;
                padding-left: 18.63636%;
                background-image: url(../img/affiliation/arrow-01.png);
            }

            &:first-child {
                width: 20.90911%;
                padding-left: 0;
                background-image: none;
            }

            .e-step {

                > span {
                    background-color: $color-white;
                    color: $color-green-02;
                }
            }
        }
    }

    @include mq(medium, max, true) {

        li {
            background-position: left 15px center;
            background-size: 10px auto;
        }
    }

    @include mq(small, max, true) {

        li {
            width: 100%;
            background-image: none;
            padding-left: 0;

            &:nth-child(4n+1) {
                width: 100%;
            }
        }

        &.pattern-01 {

            li {
                width: 100%;
                background-image: none;
                padding-left: 0;
                margin-top: 40px;

                &:nth-child(4n+1) {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }
    }
}



.m-culumn-box {
    display: flex;
    justify-content: space-between;

    .m-culumn-main {
        width: 850px;
    }

    .m-culumn-side {
        width: 310px;
    }

    @include mq(large, max, true) {

        .m-culumn-main {
            width: calc(100% - 350px);
        }
    }

    @include mq(medium, max, true) {
        display: block;

        .m-culumn-main {
            width: 100%;
        }

        .m-culumn-side {
            width: 100%;
            margin-top: 100px;
        }
    }

    .m-culumn-title {
        border-top: 1px solid $color-gray-02;
        border-bottom: 1px solid $color-gray-02;
        padding: 50px 0;
        margin-bottom: 50px;

        div {
            @include font-Amiri-R;

            span {
                border: 1px solid #5d7975;
                color: #5d7975;
                @include css-lock(1.0, 1.0);
                line-height: 1;
                margin-left: 20px;
                padding: 2px 8px 0;
            }
        }

        h1 {
            @include font-Amiri-R;
            @include css-lock(2.2, 2.2);
            font-weight: bold;
            margin-top: 20px;
        }
    }

    .e-aside {

        + .e-aside {
            margin-top: 50px;
        }

        h3 {
            background-color: $color-gray;
            @include css-lock(1.6, 1.6);
            @include font-Amiri-R;
            font-weight: bold;
            line-height: 1;
            padding: 17px;
        }

        li {

            a {
                display: table;
                width: 100%;
                padding: 15px 0;
                border-bottom: 1px solid $color-gray-02;

                .e-img {
                    display: table-cell;
                    width: 125px;

                    img {
                        width: 125px;
                        height: 90px;
                        object-fit: cover;
                        border: 1px solid #eeeeee;
                    }
                }

                .e-text {
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 20px;
                    padding-right: 30px;
                    line-height: 1.5;
                    background-image: url(../img/common/arrow-green-01.png);
                    background-repeat: no-repeat;
                    background-position: right 7px center;
                    transition: all 0.3s;
                }

                &:hover .e-text {
                    color: #999999;
                    background-position: right 2px center;
                }
            }
        }
    }
}



.m-search-box {
    background-color: $color-white;
    padding: 40px;

    > div {
        display: flex;

        dl {
            margin-left: 40px;

            &:first-child {
                margin-left: 0;
            }

            dt {
                @include font-Amiri-R;
                @include css-lock(2.2, 1.8);
                font-weight: bold;
                margin-bottom: 20px;
                line-height: 1.4;
            }

            dd {

                ul {

                    li {
                        word-break: keep-all;
                    }
                }
            }

            &.e-area {

                dt {
                    margin-bottom: 15px;
                }

                select {
                    width: 260px;
                    padding: 10px 20px;
                }
            }
        }
    }

    .e-btn {
        border-top: 1px solid $color-gray-02;
        margin-top: 30px;

        .m-btn {
            margin: 40px auto 0;

            > * {
                width: 260px;
                display: block;
            }
        }
    }

    @include mq(large, max, true) {

        > div {

            dl {

                &.e-area {

                    select {
                        width: 200px;
                    }
                }
            }
        }
    }

    @include mq(medium, max, true) {
        padding: 20px;

        > div {
            display: block;

            dl {
                margin-left: 0;

                + dl {
                    margin-top: 20px;
                }

                dt {
                    margin-bottom: 0 !important;
                }
            }
        }

        .e-btn {
            margin-top: 20px;

            .m-btn {
                margin: 20px auto 0;

                > * {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    @include mq(small, max, true) {

        dl {

            dd {

                ul {
                    display: block;

                    li {
                        margin-left: 0;
                    }
                }
            }

            &.e-area {

                select {
                    width: 100%;
                }
            }
        }

        .e-btn {

            .m-btn {

                > * {
                    width: 100%;
                }
            }
        }
    }
}



.m-type-link {
    margin-top: 50px;

    li {
        border: 1px solid $color-green;

        a {
            display: block;
            padding: 0 20px;
            height: 100%;

            dl {
                @include font-Amiri-R;

                dt {
                    @include css-lock(1.8, 1.8);
                    font-weight: bold;
                    text-align: center;
                    line-height: 1.5;
                    height: 85px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                dd {
                    @include css-lock(1.6, 1.6);
                    border-top: 1px solid $color-gray-02;
                    height: 65px;
                    display: flex;
                    align-items: center;
                    line-height: 1.3;
                    background-image: url(../img/common/arrow-green-01.png);
                    background-repeat: no-repeat;
                    background-position: right center;
                    padding-right: 20px;
                }
            }

            &:hover {
                text-decoration: none;
                background-color: $color-green;
                color: $color-white;

                dl dd {
                    background-image: url(../img/common/arrow-white-01.png);
                }
            }
        }
    }

    @include mq(medium, max, true) {
        margin-top: 50px !important;
    }
}



.m-faq {

    dl + dl {
        margin-top: 2px;
    }

    .e-faq-q {
        cursor: pointer;
        background-color: $color-gray;
        padding: 15px 40px;
        background-image: url(../img/common/arrow-green-02.png);
        background-repeat: no-repeat;
        background-position: right 20px center;

        &.open {
            background-image: url(../img/common/arrow-green-02-top.png);
        }

        span {
            margin-right: 20px;
            @include font-Amiri-B;
        }

        @include mq(medium, max, true) {
            padding: 15px 40px 15px 50px;
            text-indent: -27px;
            padding-left: 45px;

            span {
                margin-right: 10px;
            }
        }
    }

    .e-faq-a {
        padding: 40px 50px 40px 75px;

        > * + * {
            margin-top: 30px;
        }

        sup {
            font-size: 1.1rem;
            vertical-align: top;
        }

        @include mq(medium, max, true) {
            padding: 20px 50px 30px 45px;
        }

        @include mq(small, max, true) {
            padding: 20px 15px 30px 15px;
        }
    }
}



.m-seminar-info-box {
    background-color: $color-gray;
    padding: 40px;

    dd {

        .m-btn {
            margin-top: 0;
        }
    }
}



.m-certification-mark {
    margin-top: 40px;
    border: 1px solid #9ea2a2;
    padding: 20px 20px 20px 35px;

    dl {
        display: flex;

        + dl {
            margin-top: 30px;
        }

        dt {
            width: 50px;

            img {
                width: 100%;
            }
        }

        dd {
            width: calc(100% - 50px);
            padding-left: 20px;

            .e-title {
                @include font-Amiri-R;
                @include css-lock(1.8, 1.8);
                font-weight: bold;
                line-height: 1.4;
            }

            .e-text {
                line-height: 1.7;
                @include css-lock(1.4, 1.4);
            }
        }
    }

    @include mq(small, max, true) {
        padding: 20px;
    }
}



.m-certification-mark-02 {
    text-align: right;
    margin: 0 0 -50px;
    padding-top: 80px;

    > div {
        display: inline-block;
        border: 1px solid #9ea2a2;
        padding: 10px;

        dl {
            display: flex;
            align-items: center;
            text-align: left;

            dt {
                width: 78px;

                img {
                    width: 100%;
                }
            }

            dd {
                width: calc(100% - 78px);
                padding-left: 15px;
                @include font-Amiri-R;
                @include css-lock(1.4, 1.4);
                line-height: 1.4;
                font-weight: bold;
                white-space: nowrap;
            }
        }
    }

    @include mq(small, max, true) {
        margin: 0 0 -20px;
        padding-top: 50px;

        > div {
            width: 100%;
        }
    }
}



.m-certification-mark-03 {
    display: flex;
    margin-left: 30px;

    dl {
        display: flex;
        align-items: center;

        + dl {
            margin-left: 20px;
        }

        dt {
            width: 52px;

            img {
                width: 100%;
            }
        }

        dd {
            width: calc(100% - 52px);
            padding-left: 15px;
            @include font-Amiri-R;
            @include css-lock(1.2, 1.2);
            line-height: 1.4;
            font-weight: bold;
            letter-spacing: 0;

            span {
                @include css-lock(1.4, 1.4);
                display: block;
                letter-spacing: 0.06em;
            }
        }
    }

    @include mq(xlarge, max, true) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }

    @include mq(small, max, true) {
        display: block;

        dl {

            + dl {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}