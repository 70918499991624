@charset "UTF-8";

// ---------------------------
// Button
// ---------------------------

.m-btn {
    margin-top: 50px;

    > * {
        @include font-Amiri-R;
        @include css-lock(1.6, 1.6);
        color: $color-white;
        display: inline-block;
        text-align: center;
        width: 300px;
        line-height: 1;
        padding: 22px 0 18px;
        background-repeat: no-repeat;
        background-position: right 15px center;
        background-image: url(../img/common/arrow-white-01.png);
        background-color: $color-green;
        transition: all 0.3s;
        cursor: pointer;
        border: none;
        appearance:none;
        border-radius: 0;

        &:hover {
            background-color: $color-green-02;
            color: $color-white;
            text-decoration: none;
            background-position: right 10px center;
        }

        @include mq(small, max, true) {
            width: 100%;
        }
    }

    &.ja > * {
        @include css-lock(1.6, 1.6);
    }

    &.pattern-01 {
        margin-top: 70px;
    }
    &.pattern-02 {
        margin-top: 40px;
    }

    &.m-btn-small {
        margin-top: 0;

        > * {
            color: $color-green;
            border: 1px solid $color-green;
            font-size: 1.2rem;
            padding: 5px 22px 5px 15px;
            width: auto;
            background-image: url(../img/common/arrow-green-01.png);
            background-position: right 7px center;
            background-size: 4px auto;
            background-color: $color-white;

            &:hover {
                background-color: $color-green-02;
                color: $color-white;
                background-image: url(../img/common/arrow-white-01.png);
                background-position: right 5px center;
            }
        }
    }

    &.m-btn-small-02 {
        margin-top: 0;

        > * {
            color: $color-white;
            @include css-lock(1.4, 1.4);
            padding: 10px 27px 10px 20px;
            width: auto;
            background-image: url(../img/common/arrow-white-01.png);
            background-position: right 7px center;
            background-size: 4px auto;
            background-color: $color-green;

            &:hover {
                background-color: $color-green-02;
                background-image: url(../img/common/arrow-white-01.png);
                background-position: right 5px center;
            }
        }
    }

    &.m-btn-large {

        > * {
            width: 360px;

            @include mq(small, max, true) {
                width: 100%;
            }
        }
    }

    &.m-btn-length {

        @include mq(small, max, true) {

            > * {
                @include css-lock(1.2, 1.2);
            }
        }
    }

    &.m-btn-white {

        > * {
            background-color: $color-white;
            background-image: url(../img/common/arrow-green-01.png);
            border: 1px solid $color-green;
            color: $color-green;

            &:hover {
                background-color: $color-green-02;
                color: $color-white;
                background-image: url(../img/common/arrow-white-01.png);
            }
        }
    }
}



.m-sns-btn {
    display: flex;
    justify-content: center;
    margin-top: 100px;

    li {
        display: inline-block;
        width: 200px;

        a {
            @include font-Amiri-R;
            display: block;
            padding: 15px 10px 15px 35px;
            color: $color-white;
            text-align: center;
            background-position: 10px center;
            background-repeat: no-repeat;
            background-size: 24px auto;
            line-height: 1;
        }

        &.e-fb a {
            background-color: #3b5998;
            background-image: url(../img/common/icon-facebook.png);
        }

        &.e-twitter a {
            background-color: #54acee;
            background-image: url(../img/common/icon-twitter-02.png);
        }
    }

    @include mq(small, max, true) {
        display: block;

        li {
            width: 100%;

            + li {
                margin-top: 10px;
            }
        }
    }
}