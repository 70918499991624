@charset "UTF-8";

// ---------------------------
// 変数
// ---------------------------

@font-face {
    font-family: 'Amiri-R';
    src: url('../font/Amiri/Amiri-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Amiri-B';
    src: url('../font/Amiri/Amiri-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'LibreBaskerville-R';
    src: url('../font/Libre_Baskerville/LibreBaskerville-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'LibreBaskerville-I';
    src: url('../font/Libre_Baskerville/LibreBaskerville-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'LibreBaskerville-B';
    src: url('../font/Libre_Baskerville/LibreBaskerville-Bold.ttf') format('truetype');
}


$color-black:       #000000;
$color-white:       #ffffff;
$color-base:        #545b5a;
$color-gray:        #ecf1f2;
$color-gray-02:     #9ea2a2;
$color-green:       #5f7070;
$color-green-02:    #5d7975;
$color-green-03:    #e5eaeb;
$color-red:         #c91939;

$base-w: 1100;



$color-bule:        #112240;
$color-bule-02:     #042a6c;
$color-beige:       #e8e4e1;
$color-beige-02:    #f6f5f4;