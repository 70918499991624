@charset "UTF-8";

// ---------------------------
// Heading
// ---------------------------

.m-heading {
    @include font-Amiri-R;
    @include css-lock(2.1, 1.6, $property: line-height, $unit: null);

    span {
        display: block;
        letter-spacing: normal;
    }
}

.m-heading-h1-01 {
}

.m-heading-h2-01 {
    margin-bottom: 80px;
    text-align: center;

    h2 {
        @include css-lock(4.6, 4.6);
        line-height: 1.5;
        letter-spacing: 0.1em;

        span {
            @include css-lock(1.6, 1.6);
        }
    }
}
.m-heading-h2-02 {
    margin-bottom: 80px;
    text-align: center;

    @include mq(small, max, true) {

        &.pattern-01 {
            margin-bottom: 40px;
        }
    }

    h2 {
        @include css-lock(3.2, 2.8);
        line-height: 1.5;
        font-weight: bold;
    }

    .e-textimg-logo {
        width: 120px;
        margin-bottom: 20px;
    }
}
.m-heading-h2-03 {
    margin-bottom: 80px;
    text-align: center;
    border-top: 2px solid $color-gray-02;
    border-bottom: 2px solid $color-gray-02;
    padding: 40px 0 30px;

    h2 {
        @include css-lock(3.8, 2.6);
        line-height: 1.5;
        font-weight: bold;
    }
}
.m-heading-h2-04 {
    margin-bottom: 40px;
    border-bottom: 1px solid #a4a8a8;
    padding: 0 0 20px;

    h2 {
        @include css-lock(2.2, 2.2);
        line-height: 1;
        font-weight: bold;
    }
}
.m-heading-h2-05 {
    @include font-Amiri-R;
    margin: 0 0 15px 10px;
    letter-spacing: 0.1em;

    h2 {
        @include css-lock(2.2, 2.2);
        line-height: 1;
        font-weight: bold;
    }
}
.m-heading-h2-06 {
    border-bottom: 1px solid #49484f;
    text-align: left;
    padding: 0 0 10px 10px;
    margin-bottom: 40px;
    overflow: hidden;

    h2 {
        font-size: 2.8rem;
        font-weight: bold;
    }

    @include mq(small, max, true) {

        h2 {
            font-size: 2.0rem;
        }
    }
}
.m-heading-h2-07 {
    margin-bottom: 100px;
    text-align: center;
    background-color: $color-green;
    padding: 50px 0;

    h2 {
        line-height: 1;
    }

    @include mq(small, max, true) {
        margin-bottom: 80px;
        padding: 40px 20px;
    }
}



.m-heading-h3-01 {
    margin-bottom: 40px;

    h3 {
        @include css-lock(2.2, 2.2);
        font-weight: bold;
    }

    @include mq(medium, max, true) {
        text-align: center;
    }
}
.m-heading-h3-02 {
    margin-bottom: 40px;
    border-bottom: 1px solid $color-gray-02;
    position: relative;

    h3 {
        @include css-lock(2.2, 2.2);
        font-weight: bold;

        span {
            @include css-lock(1.4, 1.4);
            display: inline-block;
            margin-left: 15px;
            font-weight: bold;

            @include mq(small, max, true) {
                margin-left: 0;
            }
        }
    }

    .e-btn {
        position: absolute;
        right: 0;
        top: 0;

        @include mq(medium, max, true) {
            position: static;
            margin-top: 10px !important;
            margin-bottom: 20px;
        }
    }
}
.m-heading-h3-03 {
    margin-bottom: 10px;
    padding: 15px 0 10px;
    border-bottom: 1px solid $color-gray-02;
    border-top: 1px solid $color-gray-02;

    h3 {
        @include css-lock(2.2, 2.2);
        font-weight: bold;
    }
}
.m-heading-h3-04 {
    margin-bottom: 30px;

    h3 {
        @include css-lock(2.2, 2.2);
        font-weight: bold;
        @include font-mincho;
        line-height: 1.6;
    }

    @include mq(medium, max, true) {
        text-align: center;
    }
}



.m-heading-h4-01 {
}



.m-heading-h5-01 {
}



.m-heading-h6-01 {
}



.m-heading-news {
    @include css-lock(2.2, 2.2);
    margin-bottom: 40px;
    border-bottom: 1px solid $color-gray-02;
    padding-bottom: 40px;

    div {
        @include font-Amiri-R;
        @include css-lock(1.4, 1.4);
        margin-bottom: 10px;
        font-weight: normal;

        span {
            @include font-Amiri-R;
            display: inline-block;
            border: 1px solid $color-green-02;
            color: $color-green-02;
            line-height: 1;
            text-align: center;
            @include css-lock(0.9, 0.9);
            padding: 5px 10px;
            margin-left: 40px;
            vertical-align: text-top;

            + span {
                margin-left: 10px;
            }
        }
    }

    h1 {
        font-weight: bold;
        @include font-Amiri-R;
    }

    @include mq(medium, max, true) {

        div {

            span {
                margin-left: 20px;
            }
        }
    }
}