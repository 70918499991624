@charset "UTF-8";

// ---------------------------
// Text
// ---------------------------

.m-text-products-01 {
    @include css-lock(1.6, 1.6);
    @include font-Amiri-R;
    font-weight: bold;

    img {
        width: 20px;
        vertical-align: middle;
        margin-right: 5px;
    }
}



.m-text-dl-01,
.m-text-dl-04 {
    background-color: $color-gray;
    padding: 40px;

    dt {
        font-weight: bold;
        @include css-lock(1.8, 1.8);
        @include font-mincho;
    }

    dd {

        + dd {
            margin-top: 10px;
        }
    }

    @include mq(small, max, true) {
        padding: 20px;
    }
}
.m-text-dl-01 {

    dd {
        text-indent: -1em;
        padding-left: 1em;
    }
}

.m-text-dl-02 {
    border: 1px solid $color-black;
    padding: 30px 20px;
    text-align: center;

    dt {
        @include css-lock(1.8, 1.8);
    }
}

.m-text-dl-03 {

    dt {
        font-weight: bold;
        @include css-lock(1.6, 1.6);
        @include font-Amiri-R;
    }
    sup {
        font-size: 1.2rem;
        vertical-align: top;
    }
    span {
        font-size: 1.2rem;
    }
}

.m-text-dl-05 {
    display: flex;

    + .m-text-dl-05 {
        margin-top: 40px;
    }

    dt {
        @include css-lock(1.6, 1.6);
        background-color: #f8f7cd;
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-Amiri-R;
        font-weight: bold;
    }

    dd {
        width: calc(100% - 140px);
        padding-left: 20px;
        text-align: left;

        span {
            @include css-lock(1.6, 1.6);
            @include font-Amiri-R;
            font-weight: bold;
        }
    }

    @include mq(medium, max, true) {
        align-items: flex-start;

        dt {
            padding: 10px 0;
        }
    }

    @include mq(small, max, true) {
        display: block;

        dt {
            width: 100%;
        }
        dd {
           width: 100%;
           padding-left: 0;
           margin-top: 10px;
       }
    }
}

.m-text-dl-06 {

    + .m-text-dl-06 {
        margin-top: 50px;
    }

    dt {
        font-weight: bold;
        @include css-lock(1.6, 1.6);
        @include font-mincho;
        margin-bottom: 10px;
    }
}

.m-text-dl-07 {
    position: relative;

    dd {
        position: absolute;
        top: 0;
        right: 0;

        @include mq(medium, max, true) {
            position: static;
            margin-top: 20px;
            text-align: right;
        }
    }
}



.m-text-p {
    @include css-lock(3.2, 3.2);
    @include font-Amiri-R;
    font-weight: bold;
    margin-bottom: 50px;
}

.item-text-area {
    h4 {
        @include css-lock(1.6, 1.6);
        @include font-Amiri-R;
        font-weight: bold;
        margin-bottom: 10px;

        img {
            vertical-align: middle;
            width: 24px;
            height: 20px;
            margin-top: -5px;
        }
    }
}



.m-text-list-01 {

    li {
        padding-left: 1em;
        text-indent: -1em;
    }
}



.m-text-caution-01 {
}



.m-text-link-01 {
}
