@charset "UTF-8";

// ---------------------------
// Utility
// ---------------------------

.color-red    { color: $color-red; }

.bg-color-gray  { background-color: $color-gray; }
.bg-color-green  { background-color: $color-green-03; }
.bg-color-white  { background-color: $color-white; }



.u-font-Amiri-R { font-family: 'Amiri-R', "Hiragino Mincho ProN", 游明朝, HGS明朝E, serif; }



.u-mt-00 { margin-top: 0 !important; }
.u-mt-05 { margin-top: 5px !important; }
.u-mt-10 { margin-top: 10px !important; }
.u-mt-15 { margin-top: 15px !important; }
.u-mt-20 { margin-top: 20px !important; }
.u-mt-25 { margin-top: 25px !important; }
.u-mt-30 { margin-top: 30px !important; }
.u-mt-35 { margin-top: 35px !important; }
.u-mt-40 { margin-top: 40px !important; }
.u-mt-45 { margin-top: 45px !important; }
.u-mt-50 { margin-top: 50px !important; }
.u-mt-55 { margin-top: 55px !important; }
.u-mt-60 { margin-top: 60px !important; }
.u-mt-65 { margin-top: 65px !important; }
.u-mt-70 { margin-top: 70px !important; }
.u-mt-75 { margin-top: 75px !important; }
.u-mt-80 { margin-top: 80px !important; }
.u-mt-85 { margin-top: 85px !important; }
.u-mt-90 { margin-top: 90px !important; }
.u-mt-95 { margin-top: 95px !important; }
.u-mt-100 { margin-top: 100px !important; }

@include mq(small, max, true) {
    .u-mt-s-00 { margin-top: 0 !important; }
    .u-mt-s-05 { margin-top: 5px !important; }
    .u-mt-s-10 { margin-top: 10px !important; }
    .u-mt-s-15 { margin-top: 15px !important; }
    .u-mt-s-20 { margin-top: 20px !important; }
    .u-mt-s-25 { margin-top: 25px !important; }
    .u-mt-s-30 { margin-top: 30px !important; }
    .u-mt-s-35 { margin-top: 35px !important; }
    .u-mt-s-40 { margin-top: 40px !important; }
    .u-mt-s-45 { margin-top: 45px !important; }
    .u-mt-s-50 { margin-top: 50px !important; }
    .u-mt-s-55 { margin-top: 55px !important; }
    .u-mt-s-60 { margin-top: 60px !important; }
    .u-mt-s-65 { margin-top: 65px !important; }
    .u-mt-s-70 { margin-top: 70px !important; }
    .u-mt-s-75 { margin-top: 75px !important; }
    .u-mt-s-80 { margin-top: 80px !important; }
    .u-mt-s-85 { margin-top: 85px !important; }
    .u-mt-s-90 { margin-top: 90px !important; }
    .u-mt-s-95 { margin-top: 95px !important; }
    .u-mt-s-100 { margin-top: 100px !important; }
}

.u-mt-ll { margin-top: 50px !important; @include mq(medium, max, true) { margin-top: 40px !important; } }
.u-mt-l { margin-top: 40px !important; @include mq(medium, max, true) { margin-top: 30px !important; } }
.u-mt-m { margin-top: 30px !important; @include mq(medium, max, true) { margin-top: 20px !important; } }
.u-mt-s { margin-top: 20px !important; @include mq(medium, max, true) { margin-top: 10px !important; } }
.u-mt-ss { margin-top: 10px !important; @include mq(medium, max, true) { margin-top: 5px !important; } }



.u-mb-00 { margin-bottom: 0 !important; }
.u-mb-05 { margin-bottom: 5px !important; }
.u-mb-10 { margin-bottom: 10px !important; }
.u-mb-15 { margin-bottom: 15px !important; }
.u-mb-20 { margin-bottom: 20px !important; }
.u-mb-25 { margin-bottom: 25px !important; }
.u-mb-30 { margin-bottom: 30px !important; }
.u-mb-35 { margin-bottom: 35px !important; }
.u-mb-40 { margin-bottom: 40px !important; }
.u-mb-45 { margin-bottom: 45px !important; }
.u-mb-50 { margin-bottom: 50px !important; }
.u-mb-55 { margin-bottom: 55px !important; }
.u-mb-60 { margin-bottom: 60px !important; }
.u-mb-75 { margin-bottom: 65px !important; }
.u-mb-70 { margin-bottom: 70px !important; }
.u-mb-75 { margin-bottom: 75px !important; }
.u-mb-80 { margin-bottom: 80px !important; }
.u-mb-85 { margin-bottom: 85px !important; }
.u-mb-90 { margin-bottom: 90px !important; }
.u-mb-95 { margin-bottom: 95px !important; }
.u-mb-100 { margin-bottom: 100px !important; }

@include mq(small, max, true) {
    .u-mb-s-00 { margin-bottom: 0 !important; }
    .u-mb-s-05 { margin-bottom: 5px !important; }
    .u-mb-s-10 { margin-bottom: 10px !important; }
    .u-mb-s-15 { margin-bottom: 15px !important; }
    .u-mb-s-20 { margin-bottom: 20px !important; }
    .u-mb-s-25 { margin-bottom: 25px !important; }
    .u-mb-s-30 { margin-bottom: 30px !important; }
    .u-mb-s-35 { margin-bottom: 35px !important; }
    .u-mb-s-40 { margin-bottom: 40px !important; }
    .u-mb-s-45 { margin-bottom: 45px !important; }
    .u-mb-s-50 { margin-bottom: 50px !important; }
    .u-mb-s-55 { margin-bottom: 55px !important; }
    .u-mb-s-60 { margin-bottom: 60px !important; }
    .u-mb-s-75 { margin-bottom: 65px !important; }
    .u-mb-s-70 { margin-bottom: 70px !important; }
    .u-mb-s-75 { margin-bottom: 75px !important; }
    .u-mb-s-80 { margin-bottom: 80px !important; }
    .u-mb-s-85 { margin-bottom: 85px !important; }
    .u-mb-s-90 { margin-bottom: 90px !important; }
    .u-mb-s-95 { margin-bottom: 95px !important; }
    .u-mb-s-100 { margin-bottom: 100px !important; }
}

.u-mb-ll { margin-bottom: 50px !important; @include mq(medium, max, true) { margin-bottom: 40px !important; } }
.u-mb-l { margin-bottom: 40px !important; @include mq(medium, max, true) { margin-bottom: 30px !important; } }
.u-mb-m { margin-bottom: 30px !important; @include mq(medium, max, true) { margin-bottom: 20px !important; } }
.u-mb-s { margin-bottom: 20px !important; @include mq(medium, max, true) { margin-bottom: 10px !important; } }
.u-mb-ss { margin-bottom: 10px !important; @include mq(medium, max, true) { margin-bottom: 5px !important; } }



.u-ml-00 { margin-left: 0 !important; }
.u-ml-05 { margin-left: 5px !important; }
.u-ml-10 { margin-left: 10px !important; }
.u-ml-15 { margin-left: 15px !important; }
.u-ml-20 { margin-left: 20px !important; }
.u-ml-25 { margin-left: 25px !important; }
.u-ml-30 { margin-left: 30px !important; }
.u-ml-35 { margin-left: 35px !important; }
.u-ml-40 { margin-left: 40px !important; }
.u-ml-45 { margin-left: 45px !important; }
.u-ml-50 { margin-left: 50px !important; }
.u-ml-55 { margin-left: 55px !important; }
.u-ml-60 { margin-left: 60px !important; }
.u-ml-75 { margin-left: 65px !important; }
.u-ml-70 { margin-left: 70px !important; }
.u-ml-75 { margin-left: 75px !important; }
.u-ml-80 { margin-left: 80px !important; }
.u-ml-85 { margin-left: 85px !important; }
.u-ml-90 { margin-left: 90px !important; }
.u-ml-95 { margin-left: 95px !important; }
.u-ml-100 { margin-left: 100px !important; }



.u-mr-00 { margin-right: 0 !important; }
.u-mr-05 { margin-right: 5px !important; }
.u-mr-10 { margin-right: 10px !important; }
.u-mr-15 { margin-right: 15px !important; }
.u-mr-20 { margin-right: 20px !important; }
.u-mr-25 { margin-right: 25px !important; }
.u-mr-30 { margin-right: 30px !important; }
.u-mr-35 { margin-right: 35px !important; }
.u-mr-40 { margin-right: 40px !important; }
.u-mr-45 { margin-right: 45px !important; }
.u-mr-50 { margin-right: 50px !important; }
.u-mr-55 { margin-right: 55px !important; }
.u-mr-60 { margin-right: 60px !important; }
.u-mr-75 { margin-right: 65px !important; }
.u-mr-70 { margin-right: 70px !important; }
.u-mr-75 { margin-right: 75px !important; }
.u-mr-80 { margin-right: 80px !important; }
.u-mr-85 { margin-right: 85px !important; }
.u-mr-90 { margin-right: 90px !important; }
.u-mr-95 { margin-right: 95px !important; }
.u-mr-100 { margin-right: 100px !important; }



.u-pt-00 { padding-top: 0 !important; }
.u-pt-05 { padding-top: 5px !important; }
.u-pt-10 { padding-top: 10px !important; }
.u-pt-15 { padding-top: 15px !important; }
.u-pt-20 { padding-top: 20px !important; }
.u-pt-25 { padding-top: 25px !important; }
.u-pt-30 { padding-top: 30px !important; }
.u-pt-35 { padding-top: 35px !important; }
.u-pt-40 { padding-top: 40px !important; }
.u-pt-45 { padding-top: 45px !important; }
.u-pt-50 { padding-top: 50px !important; }
.u-pt-55 { padding-top: 55px !important; }
.u-pt-60 { padding-top: 60px !important; }

.u-pt-ll { padding-top: 50px !important; @include mq(medium, max, true) { padding-top: 40px !important; } }
.u-pt-l  { padding-top: 40px !important; @include mq(medium, max, true) { padding-top: 30px !important; } }
.u-pt-m  { padding-top: 30px !important; @include mq(medium, max, true) { padding-top: 20px !important; } }
.u-pt-s  { padding-top: 20px !important; @include mq(medium, max, true) { padding-top: 10px !important; } }
.u-pt-ss { padding-top: 10px !important; @include mq(medium, max, true) { padding-top: 5px !important; } }

.u-pb-00 { padding-bottom: 0 !important; }
.u-pb-05 { padding-bottom: 5px !important; }
.u-pb-10 { padding-bottom: 10px !important; }
.u-pb-15 { padding-bottom: 15px !important; }
.u-pb-20 { padding-bottom: 20px !important; }
.u-pb-25 { padding-bottom: 25px !important; }
.u-pb-30 { padding-bottom: 30px !important; }
.u-pb-35 { padding-bottom: 35px !important; }
.u-pb-40 { padding-bottom: 40px !important; }
.u-pb-45 { padding-bottom: 45px !important; }
.u-pb-50 { padding-bottom: 50px !important; }
.u-pb-55 { padding-bottom: 55px !important; }
.u-pb-60 { padding-bottom: 60px !important; }

.u-pb-ll { padding-bottom: 50px !important; @include mq(medium, max, true) { padding-bottom: 40px !important; } }
.u-pb-l  { padding-bottom: 40px !important; @include mq(medium, max, true) { padding-bottom: 30px !important; } }
.u-pb-m  { padding-bottom: 30px !important; @include mq(medium, max, true) { padding-bottom: 20px !important; } }
.u-pb-s  { padding-bottom: 20px !important; @include mq(medium, max, true) { padding-bottom: 10px !important; } }
.u-pb-ss { padding-bottom: 10px !important; @include mq(medium, max, true) { padding-bottom: 5px !important; } }

.u-pr-00 { padding-right: 0 !important; }



.u-center { text-align: center !important; }
.u-left { text-align: left !important; }
.u-right { text-align: right !important; }

.u-center-left-l { text-align: center !important; @include mq(large, max, true) { text-align: left !important; } }
.u-center-right-l { text-align: center !important; @include mq(large, max, true) { text-align: right !important; } }
.u-left-center-l { text-align: left !important; @include mq(large, max, true) { text-align: center !important; } }
.u-right-center-l { text-align: right !important; @include mq(large, max, true) { text-align: center !important; } }

.u-center-left-m { text-align: center !important; @include mq(medium, max, true) { text-align: left !important; } }
.u-center-right-m { text-align: center !important; @include mq(medium, max, true) { text-align: right !important; } }
.u-left-center-m { text-align: left !important; @include mq(medium, max, true) { text-align: center !important; } }
.u-right-center-m { text-align: right !important; @include mq(medium, max, true) { text-align: center !important; } }

.u-center-left-s { text-align: center !important; @include mq(small, max, true) { text-align: left !important; } }
.u-center-right-s { text-align: center !important; @include mq(small, max, true) { text-align: right !important; } }
.u-left-center-s { text-align: left !important; @include mq(small, max, true) { text-align: center !important; } }
.u-right-center-s { text-align: right !important; @include mq(small, max, true) { text-align: center !important; } }



.u-block { display: block !important; }
.u-none { display: none !important; }
.u-inline-block { display: inline-block !important; }



.u-bold {
    font-weight: bold !important;
}
.u-normal {
    font-weight: normal !important;
}



.u-w-100 { width: 100% !important; }
.u-w-90 { width: 90% !important; }
.u-w-80 { width: 80% !important; }
.u-w-70 { width: 70% !important; }
.u-w-60 { width: 60% !important; }
.u-w-50 { width: 50% !important; }
.u-w-40 { width: 40% !important; }
.u-w-30 { width: 30% !important; }
.u-w-20 { width: 20% !important; }
.u-w-10 { width: 10% !important; }
.u-w-00 { width: 0% !important; }



.u-lh-base { line-height: 1; }
.u-lh-12 { line-height: 1.2; }
.u-lh-13 { line-height: 1.3; }
.u-lh-14 { line-height: 1.4; }
.u-lh-16 { line-height: 1.6; }
.u-lh-18 { line-height: 1.8; }
.u-lh-20 { line-height: 2.0; }



.u-fs-10 { font-size: 1.0rem; }
.u-fs-11 { font-size: 1.1rem; }
.u-fs-12 { font-size: 1.2rem; }
.u-fs-13 { font-size: 1.3rem; }
.u-fs-14 { font-size: 1.4rem; }
.u-fs-15 { font-size: 1.5rem; }
.u-fs-16 { font-size: 1.6rem; }
.u-fs-17 { font-size: 1.7rem; }
.u-fs-18 { font-size: 1.8rem; }
.u-fs-19 { font-size: 1.9rem; }
.u-fs-20 { font-size: 2.0rem; }
.u-fs-21 { font-size: 2.1rem; }
.u-fs-22 { font-size: 2.2rem; }
.u-fs-23 { font-size: 2.3rem; }
.u-fs-24 { font-size: 2.4rem; }
.u-fs-25 { font-size: 2.5rem; }
.u-fs-26 { font-size: 2.6rem; }
.u-fs-27 { font-size: 2.7rem; }
.u-fs-28 { font-size: 2.8rem; }
.u-fs-29 { font-size: 2.9rem; }
.u-fs-30 { font-size: 3.0rem; }
.u-fs-31 { font-size: 3.1rem; }
.u-fs-32 { font-size: 3.2rem; }
.u-fs-33 { font-size: 3.3rem; }
.u-fs-34 { font-size: 3.4rem; }
.u-fs-35 { font-size: 3.5rem; }
.u-fs-36 { font-size: 3.6rem; }
.u-fs-37 { font-size: 3.7rem; }
.u-fs-38 { font-size: 3.8rem; }
.u-fs-39 { font-size: 3.9rem; }
.u-fs-40 { font-size: 4.0rem; }
.u-fs-41 { font-size: 4.1rem; }
.u-fs-42 { font-size: 4.2rem; }
.u-fs-43 { font-size: 4.3rem; }
.u-fs-44 { font-size: 4.4rem; }
.u-fs-45 { font-size: 4.5rem; }
.u-fs-46 { font-size: 4.6rem; }
.u-fs-47 { font-size: 4.7rem; }
.u-fs-48 { font-size: 4.8rem; }
.u-fs-49 { font-size: 4.9rem; }
.u-fs-50 { font-size: 5.0rem; }
.u-fs-51 { font-size: 5.1rem; }
.u-fs-52 { font-size: 5.2rem; }
.u-fs-53 { font-size: 5.3rem; }
.u-fs-54 { font-size: 5.4rem; }
.u-fs-55 { font-size: 5.5rem; }
.u-fs-56 { font-size: 5.6rem; }
.u-fs-57 { font-size: 5.7rem; }
.u-fs-58 { font-size: 5.8rem; }
.u-fs-59 { font-size: 5.9rem; }
.u-fs-60 { font-size: 6.0rem; }



.u-border-top {
    border-top: 1px solid $color-gray-02;
}
.u-border-bottom {
    border-bottom: 1px solid $color-gray-02;
}



.u-cf::after{
    content: "";
    display: block;
    clear: both;
}



.u-center-margin {
    margin-left: auto;
    margin-right: auto;
}



.u-pc-none {
    display: none;

    @include mq(small, max, true) {
        display: block;
    }
}
.u-pc-none-inline {
    display: none;

    @include mq(small, max, true) {
        display: inline;
    }
}
.u-tb-none {
    display: block;

    @include mq(large, max, true) {
        display: none;
    }
}
.u-tb-visible {
    display: none;

    @include mq(large, max, true) {
        display: block;
    }
}
.u-sp-none {
    display: block;

    @include mq(small, max, true) {
        display: none;
    }
}
.u-sp-none-inline {
    display: inline;

    @include mq(small, max, true) {
        display: none;
    }
}