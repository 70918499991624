@charset "UTF-8";

// ---------------------------
// Mixin
// ---------------------------



// 天地左右中央寄せ（flex）
/*
@include mix-flex;
*/
@mixin mix-flex($display: flex, $justify-content: center, $align-items: center) {
    display: $display;
    justify-content: $justify-content;
    align-items: $align-items;
}



// 天地左右中央寄せ（flex）
/*
@include mix-center-flex;
*/
@mixin mix-center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}



// 天地左右中央寄せ（transform）
/*
@include mix-center-transform;
*/
@mixin mix-center-transform {
    position: relative;

    > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}



// 天地左右中央寄せ（table）
/*
@include mix-center-table;
*/
@mixin mix-center-table {
    display: table;
    width: 100%;
    text-align: center;

    > * {
        display: table-cell;
        vertical-align: middle;
    }
}



// ウェブフォント指定
@mixin font-Roboto-R {
    font-family: 'Roboto-R', '游ゴシック', '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;;
}
@mixin font-Roboto-B {
    font-family: 'Roboto-B', '游ゴシック', '游ゴシック体', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;;
}

// ウェブフォント指定
@mixin font-Amiri-R {
    font-family: 'Amiri-R', "Hiragino Mincho ProN", 游明朝, HGS明朝E, serif;
}
@mixin font-Amiri-B {
    font-family: 'Amiri-B', "Hiragino Mincho ProN", 游明朝, HGS明朝E, serif;
}
@mixin font-LibreBaskerville-R {
    font-family: 'LibreBaskerville-R', "Hiragino Mincho ProN", 游明朝, HGS明朝E, serif;
}
@mixin font-LibreBaskerville-I {
    font-family: 'LibreBaskerville-I', "Hiragino Mincho ProN", 游明朝, HGS明朝E, serif;
}
@mixin font-LibreBaskerville-B {
    font-family: 'LibreBaskerville-B', "Hiragino Mincho ProN", 游明朝, HGS明朝E, serif;
}



@mixin font-gothic {
    font-family:
                'Hiragino Kaku Gothic ProN',
                YuGothicM,        /* Windows8.1以上は游ゴシック */
                YuGothic,         /* Macの游ゴシック */
                Meiryo,           /* 游ゴシックが入っていないWindows */
                sans-serif;

    /* IE8〜11はメイリオ */
    @media screen\0 {
        body {
            font-family:
                        Meiryo,     /* メイリオ */
                        sans-serif;
        }
    }
    /* Windows */
    @font-face {
        font-family: YuGothicM;
        font-weight: normal;
        src:
            local('YuGothic-Medium'),  /* Mediumを明示的に指定 */
            local('Yu Gothic Medium'), /* Chrome用 */
            local('YuGothic-Regular'); /* Windows8.1ではMediumがないのでRegularを指定 */
    }
    @font-face {
        font-family: YuGothicM;
        font-weight: bold;
        src:
            local('YoGothic-Bold'), /* Boldはそのまま */
            local('Yu Gothic');     /* Chrome用 */
    }
}

@mixin font-mincho {
    font-family:
                'Hiragino Mincho ProN',
                YuMincho,    /* Macの游明朝 */
                'Yu Mincho', /* Windowsの游明朝 */
                HGS明朝E,
                'MS Mincho', /* 游明朝がないWindows（MS PMinchoなら字詰めされる） */
                serif;

   /* IE8〜11はMS明朝 */
   @media screen\0 {
      body {
          font-family: HGS明朝E, 'MS Mincho', serif;
      }
   }
}



// フォントサイズ レスポンシブ
// https://coliss.com/articles/build-websites/operation/css/font-size-value-for-responsive.html
// https://codepen.io/jmm/pen/amGzOj
//
// 使用方法
// フォントサイズ
// @include css-lock(3.6, 1.4); 最大値, 最小値
// 行間
// @include css-lock(1.8, 1.6, $property: line-height, $unit: null); 最大値, 最小値

@mixin css-lock($max-size: 1.6, $min-size: 1.4, $min-width: 48, $max-width: 128.0, $property: font-size, $unit: rem) {
    #{$property}: #{$max-size}#{$unit};

    //@media (max-width: #{$max-width}#{$unit}) {
    @include mq(large, max, true) { // タブレット横
        #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})));
    }

    //@media (max-width: #{$min-width}#{$unit}) {
    @include mq(small, max, true) { // スマートフォン
        #{$property}: #{$min-size}#{$unit};
    }
}




@mixin padding($padding-top, $padding-bottom) {
    padding: calc((#{$padding-top}px / #{$base-w}) * 100) 0 calc((#{$padding-bottom}px / #{$base-w}) * 100);
}
