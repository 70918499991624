@charset "UTF-8";

// ---------------------------
// base
// ---------------------------

html {
    font-size: 62.5%;
}

body {
    @include font-gothic;
    //@include font-mincho;

    @include css-lock(1.4, 1.4);
    @include css-lock(2.1, 2.1, $property: line-height, $unit: null);
    color: $color-base;
    word-wrap: break-word;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    background-color: $color-white;
    letter-spacing: 0.06em;
}





h1, h2, h3, h4, h5, h6 {
}

h1 {

    &.en {

    }
}

h2 {

    &.en {

    }
}

h3 {

    &.en {

    }
}

h4 {

    &.en {

    }
}

h5 {

    &.en {

    }
}

h6 {

    &.en {

    }
}

p {

    + p {
        margin-top: 30px;
    }
}

img {
    pointer-events: none;
    vertical-align: bottom;
    transition: all 0.3s;

    a & {
        pointer-events: auto;
    }

    a:hover & {
        opacity: 0.8;
    }

    @include mq(medium, max, true) { //767px 以下
        max-width: 100%;
    }
}

a {
    text-decoration: none;
    color: $color-green;

    &:hover {
        text-decoration: underline;
        color: $color-green-02;
    }

    &.line {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

ul {

    li {
    }
}

ol {

    li {
    }
}

dl {

    dt {
    }

    dd {
    }
}

table {

    thead {

        tr {
        }

        th {
            font-weight: normal;
        }

        td {
            font-weight: normal;
        }
    }

    tbody {

        tr {
        }

        th {
            font-weight: normal;
        }

        td {
            font-weight: normal;
        }
    }
}

strong {
}

em {
}

small {
}

blockqoute {
}



@media print{
    .l-header,
    .l-side,
    .l-footer {
        display: none !important;
    }

    .l-main {
        width: 100% !important;
    }
}