@charset "UTF-8";

// ---------------------------
// List
// ---------------------------

.m-shop-list {
    background-color: #f6f5f4;
    padding: 40px;
}



.m-dot-list-01 {
    padding-left: 1em;
    text-indent: -1em;
}



.m-breadcrumb-list {
    padding: 10px 80px 0 !important;
    @include font-mincho;

    @include mq(xlarge, max, true) {
        padding: 10px 40px 0 !important;
    }

    @include mq(large, max, true) {
        padding: 20px 20px 0 !important;
    }

    ul {
        display: flex;
        flex-wrap: wrap;

        li {

            &::before {
                content: '>';
                display: inline-block;
                margin-left: 10px;
                margin-right: 10px;
            }

            &:first-child::before {
                display: none;
            }

            a {
                color: $color-base;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .m-return {
        margin-top: 30px;

        img {
            vertical-align: inherit;
            margin-right: 5px;
        }
    }
}



.m-products-component-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -15px;
    max-height: 0;
    overflow: hidden;

    &.open {
        transition: 1.0s;
        max-height: 10000px !important;
    }

    dl {
        width: calc((100% / 6) - 9px);
        margin-left: 10px;
        text-align: center;
        margin-top: 20px;

        &:first-child,
        &:nth-child(6n+1) {
            margin-left: 0;
        }

        dt {
            border: 1px solid $color-gray-02;
            width: 100%;
            padding: 30px 0 30px;

            img {
                width: 135px;
            }
        }

        dd {
            margin-top: 10px;
            line-height: 1.5;

            &.purpose {
                margin-top: 0;
                @include css-lock(1.2, 1.2);
            }
        }

        @include mq(medium, max, true) {
            width: calc((100% / 4) - 8px);

            &:nth-child(6n+1) {
                margin-left: 10px;
            }
            &:first-child,
            &:nth-child(4n+1) {
                margin-left: 0;
            }
        }

        @include mq(small, max, true) {
            width: calc((100% / 2) - 6px);

            &:nth-child(4n+1) {
                margin-left: 10px;
            }
            &:first-child,
            &:nth-child(2n+1) {
                margin-left: 0;
            }
        }
    }
}
.m-products-component-list-link {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    text-decoration: underline;
    cursor: pointer;
    color: $color-green;

    &:hover {
        color: $color-green-02;
        text-decoration: none;
    }
}



.m-products-list {

    > ul {
        margin-top: -25px;
        margin-left: -25px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        > li {
            width: calc((100% / 3) - 25px);
            margin-left: 25px;
            margin-top: 25px;
            background-color: $color-white;

            a {
                color: $color-base;

                &:hover {
                    text-decoration: none;

                    & h3 {
                        text-decoration: underline;
                    }
                }
            }
        }

        @include mq(medium, max, true) {

            > li {
                width: calc((100% / 2) - 25px);
            }
        }

        @include mq(small, max, true) {
            margin-left: 0;

            > li {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .e-main {
        height: 440px;
        padding: 30px 25px 0;

        .e-head {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;

            dt {
                background-color: #f8f7cd;
                @include font-Amiri-R;
                letter-spacing: 0.04em;
                line-height: 1;
                padding: 5px 15px;

                span {
                    @include css-lock(2.0, 2.0);
                }
            }

            dd {
                margin-left: 20px;
                @include font-Amiri-R;
            }
        }

        .e-img {
            text-align: center;

            img {
                height: 150px;
            }
        }

        .e-text {
            margin-top: 10px;

            .e-cate {
                border: 1px solid $color-gray-02;
                @include font-Amiri-R;
                @include css-lock(1.0, 1.0);
                line-height: 1;
                display: inline-block;
                padding: 4px 5px 0;
            }

            h4 {
                @include font-Amiri-R;
                @include css-lock(1.6, 1.6);
                color: $color-green;
                font-weight: bold;
                line-height: 1.8;
            }

            .e-text-02 {
                line-height: 1.4;
                margin-top: 15px;

                span {
                    font-family: sans-serif;
                    font-size: 0.95rem;
                    display: block;
                    margin-top: 7px;
                    line-height: 1.3;
                }
            }

            .e-price {
                @include css-lock(1.2, 1.2);
                margin-top: 10px;
            }
        }

        &.pattern-01  {
            height: 420px;
        }
    }

    .e-sub {
        @include font-Amiri-R;
        border-top: 1px solid $color-gray-02;
        color: $color-green;
        text-align: center;
        line-height: 1;
        padding: 15px 0;
        background-image: url(../img/common/arrow-green-01.png);
        background-repeat: no-repeat;
        background-position: right 17px center;
        transition: all 0.3s;
    }

    a:hover .e-sub {
        background-color: $color-green-02;
        color: $color-white;
        background-image: url(../img/common/arrow-white-01.png);
    }

    &.pattern-01,
    &.pattern-02 {
        margin-top: 50px;

        .e-heading {
            @include css-lock(1.8, 1.8);
            @include font-Amiri-R;
            font-weight: bold;
            margin-bottom: 10px;
        }

        > ul {
            margin-top: -20px;
            margin-left: -20px;

            > li {
                width: calc((100% / 2) - 20px);
                margin-left: 20px;
                margin-top: 20px;
                background-color: $color-white;

                @include mq(small, max, true) {
                    width: 100%;
                }
            }
        }

        .e-main {
            height: 330px;
            padding: 30px 20px 0;

            .e-head {

                dd {
                    margin-left: 10px;
                }
            }

            .e-text {
                margin-top: 15px;

                .e-price {
                    margin-top: 0;
                }
            }
        }
    }
    &.pattern-01 {
        padding-right: 10px;
    }
    &.pattern-02 {
        padding-left: 10px;
    }

    @include mq(medium, max, true) {

        &.pattern-01 {
            padding-right: 0;
        }
        &.pattern-02 {
            padding-left: 0;
        }
    }
}



.m-link-list {

    a {
        color: $color-base;

        &:hover {
            text-decoration: none;

            .e-img img {
                transform: scale(1.1);
            }
        }
    }

    .e-img {
        overflow: hidden;

        img {
            width: 100%;
            height: 220px;
        }
    }

    .e-sub {
        @include css-lock(1.8, 1.8);
        @include font-Amiri-R;
        background-color: $color-white;
        line-height: 1.3;
        padding: 15px 0 15px 20px;
        background-image: url(../img/common/arrow-green-01.png);
        background-repeat: no-repeat;
        background-position: right 17px center;
        border-bottom: 1px solid $color-gray-02;
        font-weight: bold;
    }
}



.m-cate-list {
    border-top: 1px solid $color-green;
    border-left: 1px solid $color-green;
    @include css-lock(1.6, 1.4);
    @include font-Amiri-R;

    li {
        border-bottom: 1px solid $color-green;
        border-right: 1px solid $color-green;
        line-height: 1.2;

        a {
            display: block;
            padding: 0 20px;
            height: 75px;
            background-image: url(../img/common/arrow-green-02.png);
            background-repeat: no-repeat;
            background-position: right 17px center;
            position: relative;

            span {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &:hover {
                background-color: $color-green-02;
                background-image: url(../img/common/arrow-white-02.png);
                color: $color-white;
                text-decoration: none;
            }
        }
    }

    @include mq(small, max, true) {

        li {

            a {
                padding-left: 10px;
                background-position: right 10px center;
            }
        }
    }
}



.m-plant-list {

    > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: -20px;

        @include mq(small, max, true) {
            justify-content: center;
        }

        dl {
            width: 240px;
            margin-top: 20px;

            dt {
                margin-bottom: 10px;
            }

            dd {

                + dd {
                    @include css-lock(1.0, 1.0);
                }
            }
        }
    }
}



.m-newscate-list {
    border-top: 1px solid $color-gray-02;
    border-bottom: 1px solid $color-gray-02;
    padding: 15px 0 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    @include css-lock(1.6, 1.6);
    @include font-Amiri-R;

    li {
        border-left: 1px solid $color-gray-02;
        line-height: 1;
        margin-top: 25px;
        margin-bottom: 5px;

        &:first-child {
            border: none;
        }

        & a,
        & .e-zero {
            display: inline-block;
            padding: 0 15px;

            &:hover {
                text-decoration: none;
            }
        }

        & .e-zero {
            color: #cccccc;
        }

        a.current span,
        a:hover span {
            border-bottom: 1px solid #192227;
        }
    }

    &.pattern-01 {
        border-top: none;
        border-bottom: none;
        padding: 0;
        justify-content: flex-start;
        width: inherit;

        .border-none {
            border-left: none;
        }
    }

    @include mq(large, max, true) {
        width: 100%;
    }

    @include mq(medium, max, true) {
        padding: 20px 10px 40px;
        justify-content: flex-start;

        li {
            width: 50%;
            margin-top: 20px;
            text-align: center;
            border: none;
        }
    }

    @include mq(small, max, true) {

        li {

            & a,
            & .e-zero {
                padding: 0;
            }
        }
    }
}



.m-column-list {
    text-align: left;

    a {
        color: #3a403f;

        &:hover {
            text-decoration: none;
        }
    }

    .e-img {
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 240px;
            border: 1px solid #e5e5e5;

            @include mq(xlarge, max, true) {
                height: 200px;
            }
        }
    }

    a:hover .e-img img {
        transform: scale(1.1);
    }

    .e-text {

        dl {
            padding: 30px 0;
            height: 200px;
            line-height: 1.6;

            dt {
                @include css-lock(1.8, 1.8);
                @include font-Amiri-R;
                font-weight: bold;
                margin-bottom: 10px;
            }

            dd {
            }
        }

        ul {
            display: table;
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid #999998;
            @include font-Amiri-R;

            li {
                display: table-cell;
                padding-left: 10px;
                line-height: 1.3;
                padding: 3px 5px 3px 10px;
                vertical-align: middle;

                &.e-date {
                    border-right: 1px solid #999998;
                    width: 100px;
                    text-align: center;
                    padding-left: 0;
                }
            }
        }

        @media screen and (max-width: 1630px) {
            dl {
                //height: 310px;
            }
        }

        @include mq(xxlarge, max, true) {//1366px 以下

            dl {
                height: 210px;
            }
        }

        @include mq(xlarge, max, true) {//1280px 以下

            dl {
                padding: 15px 15px;
                height: 220px;

                dt {
                    font-size: 1.6rem;
                }
            }

            ul {

                li {
                    font-size: 1.2rem;
                    height: 50px;
                }
            }
        }

        @include mq(large, max, true) {//1024px 以下
            dl {
                height: 240px;
            }
        }

        @include mq(medium, max, true) {//768px 以下
            dl {
                height: 200px;
            }

            ul {

                li {
                    height: 40px;
                }
            }
        }

        @include mq(small, max, true) {//768px 以下
            dl {
                height: 180px;
            }
        }
    }

    a:hover .e-text dl dt {
        text-decoration: underline;
    }
}



.m-pager-list-01 {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    @include font-LibreBaskerville-R;

    div {
        margin: 0 10px;
    }

    ul {
        display: flex;
        justify-content: center;

        li {
            margin: 0 10px;

            a {

                &:hover {
                    text-decoration: underline;
                }
            }

            &.current a {
                text-decoration: underline;
            }
        }
    }
}

.m-pager-list-02 {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;

    li {
        width: calc(100% / 3);
        line-height: 1;

        a {

            &:hover {
                text-decoration: underline;
            }
        }
    }

    img {
        vertical-align: baseline;
        margin: 0 10px;
    }

    li.e-center img {
        vertical-align: top;
    }
}



.m-news-list-01 {
    display: table;
    width: 100%;

    > div {
        display: table-cell;
        vertical-align: top;
    }

    .e-sub {
        width: 250px;
    }

    @include mq(xlarge, max, true) {

        .e-sub {
            width: 215px;
        }
    }
    @include mq(medium, max, true) {
        display: block;

        > div {
            display: block;
        }

        .e-sub {
            width: 100%;
        }
    }
}
.m-news-list-01,
.m-news-list-02 {

    .e-main {

        li {
            border-top: 1px solid $color-gray-02;

            a {
                display: block;

                &:hover {
                    text-decoration: none;
                }

                > dl {
                    display: flex;
                    justify-content: flex-start;
                    padding: 15px 5px;

                    > dt {
                        width: 260px;
                        @include css-lock(1.4, 1.4);
                        @include font-Amiri-R;

                        span {
                            @include css-lock(0.9, 0.9);
                            display: inline-block;
                            border: 1px solid $color-green-02;
                            color: $color-green-02;
                            width: 100px;
                            line-height: 1;
                            text-align: center;
                            padding: 7px 0 5px;
                            margin-left: 30px;
                        }
                    }

                    > dd {
                        width: calc(100% - 260px);

                        > dl {

                            dt {
                                @include font-Amiri-R;
                                @include css-lock(2.0, 1.6);
                                line-height: 1.6;
                                margin-bottom: 10px;
                                margin-top: -5px;
                            }
                        }

                        &.e-title {
                            @include font-Amiri-R;
                            @include css-lock(1.8, 1.6);
                            line-height: 1.6;
                            font-weight: bold;
                        }
                    }

                    @include mq(small, max, true) {
                        display: block;

                        > dt {
                            width: 100%;
                        }
                        > dd {
                            width: 100%;
                            margin-top: 10px;
                        }
                    }
                }

                &:hover > dl > dd dt,
                &:hover > dl > dd.e-title {
                    text-decoration: underline;
                }
            }

            &:first-child {
                border-top: none;

                a > dl {
                    padding-top: 0;
                }
            }
        }
    }
}
.m-news-list-02 {
    border-bottom: 1px solid $color-gray-02;

    .e-main {

        li {
            border-top: 1px solid $color-gray-02;

            a {

                > dl {
                    padding: 30px 5px;

                    > dd {

                        > dl dt {
                            font-weight: bold;
                        }
                    }
                }
            }

            &:first-child {
                border-top: 1px solid $color-gray-02;

                a > dl {
                    padding: 30px 5px;
                }
            }
        }
    }
}
.m-news-list-03 {
    display: flex;

    > li {
        width: calc(100% / 3);
        border-left: 1px solid $color-gray-02;

        &:first-child {
            border-left: none;
        }

        a {
            display: block;
            padding: 40px;

            &:hover {
                text-decoration: none;

                dd {
                    text-decoration: underline;
                }
            }

            dt {
                @include css-lock(1.6, 1.6);
                @include font-Amiri-R;
                margin-bottom: 10px;

                span {
                    @include css-lock(1.2, 1.2);
                    display: inline-block;
                    border: 1px solid $color-green-02;
                    color: $color-green-02;
                    width: 90px;
                    line-height: 1;
                    text-align: center;
                    padding: 7px 0 5px;
                    margin-left: 20px;
                }
            }
        }
    }

    @include mq(medium, max, true) {

        > li {

            a {
                padding: 20px;

                dt {

                    span {
                        margin-left: 0;
                        display: block;
                    }
                }
            }
        }
    }

    @include mq(small, max, true) {
        display: block;

        > li {
            width: 100%;
            border-left: none;
            border-top: 1px solid $color-gray-02;

            &:first-child {
                border-top: none;
            }

            a {
                padding: 20px 0;

                dt {

                    span {
                        margin-left: 10px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}



.m-seminar-cate-list {
    background-color: $color-gray;
    padding: 40px;
    @include font-Amiri-R;
    margin-bottom: 100px;

    > dl {

        + dl {
            margin-top: 30px;
        }

        dt {
            @include css-lock(1.8, 1.8);
            font-weight: bold;
            margin-bottom: 10px;
            margin-left: 15px;

            @include mq(medium, max, true) {
                margin-bottom: 20px;
            }

            @include mq(small, max, true) {
                margin-left: 0;
            }
        }

        dd {

            ul {
                margin-top: -25px;

                li {
                }

                &.e-seminar li {

                    @include mq(small, max, true) {
                        width: 100%;
                        text-align: left;
                    }
                }
            }
        }
    }
}



.m-category-list {
    background-color: $color-gray;
    padding: 40px;
    @include font-Amiri-R;

    > dl {
        display: flex;
        align-items: center;

        dt {
            width: 100px;
            font-weight: bold;
            @include css-lock(1.8, 1.8);
        }

        dd {
            @include css-lock(1.6, 1.6);
            vertical-align: text-bottom;
        }
    }

    ul {
        display: flex;

        li {
            margin-bottom: 5px;

            &::before {
                content: '|';
                margin: 0 20px;

            }

            &:first-child::before {
                display: none;
            }

            a:hover {
                text-decoration: none;
            }

            a.current span,
            a:hover span {
                border-bottom: 1px solid $color-base;
            }

            .e-zero {
                color: #bbbbbb;
            }

            li {

                &::before {
                    display: none;
                }
            }

            @include mq(medium, max, true) {//768px 以下

                li + li {
                    margin-top: 15px;
                }
            }
        }
    }

    @include mq(small, max, true) {
        padding: 20px;

        dl {
            display: block;

            + dl {
                margin-top: 20px;
            }

            dt {
                width: 100%;
            }

            dd {
                font-size: 1.4rem;
            }
        }

        ul {

            li {

                &::before {
                    content: '|';
                    margin: 0 5px;
                }
            }
        }
    }
    @include mq(xsmall, max, true) {

        dl dd {
            font-size: 1.2rem;
        }
    }

    &.pattern-01 {
        background-color: $color-white;
        border-top: 1px solid #49484f;
        border-bottom: 1px solid #49484f;
        padding: 30px 10px;
        margin-top: 60px;
    }
}



.m-agency-list {
    border-bottom: 1px solid #a4a8a8;

    > li {
        display: flex;
        border-top: 1px solid #a4a8a8;
        padding: 40px 0;

        .e-img {
            width: 11%;

            img {
                width: 100%;
                height: 90px;
                border: 1px solid $color-gray-02;
            }
        }

        .e-info {
            width: 29%;
            padding-left: 20px;

            .e-company {
                @include font-Amiri-R;
                @include css-lock(1.8, 1.8);
                font-weight: bold;
                line-height: 1.3;
                margin-bottom: 10px;
            }
        }

        .e-text {
            width: 60%;
            padding-left: 70px;
        }

        @include mq(medium, max, true) {
            flex-wrap: wrap;

            .e-img {
                width: 180px;

                img {
                    height: 180px;
                }
            }

            .e-info {
                width: calc(100% - 180px);
            }

            .e-text {
                width: 100%;
                padding-left: 0;
                margin-top: 30px;
            }
        }

        @include mq(small, max, true) {

            .e-img {
                width: 90px;

                img {
                    height: 80px;
                }
            }

            .e-info {
                width: calc(100% - 90px);
            }
        }
    }
}



.m-salon-list {
    border-bottom: 1px solid #a4a8a8;

    > li {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #a4a8a8;
        padding: 40px 0;

        .e-img {
            width: 11%;

            img {
                width: 100%;
                height: 90px;
            }
        }

        .e-info {
            width: 38%;
            padding: 0 20px;

            dt {
                @include css-lock(1.2, 1.2);

                span::before {
                    content: '｜';
                }

                span:first-child::before {
                    content: '';
                }
            }

            dd {
                @include font-Amiri-R;
                @include css-lock(1.8, 1.8);
                font-weight: bold;
                line-height: 1.33;
                margin-bottom: 10px;
            }

            ul {
                display: flex;

                li {
                    background-color: #f8f7cd;
                    width: calc(100% / 3);
                    @include css-lock(1.2, 1.2);
                    margin-left: 10px;
                    text-align: center;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        .e-address {
            width: 39%;
            padding-right: 20px;
        }

        .e-btn {
            width: 12%;

            .m-btn {
                margin-top: 10px;

                &:first-child {
                    margin-top: 0;
                }

                > * {
                    @include css-lock(1.6, 1.2);
                }
            }

            a {
                width: 100%;
                padding: 12px 0;
            }
        }

        .e-certification {
            width: 100%;
            margin-top: 15px;

            li {
                border: 1px solid #9ea2a2;
                padding: 15px 0;
                text-align: center;

                img {
                    height: 60px;
                    width: auto;
                }
            }
        }
    }

    @include mq(large, max, true) {

        > li {

            .e-info {
                width: 37%;
            }

            .e-address {
                width: 38%;
            }

            .e-btn {
                width: 14%;
            }
        }
    }

    @include mq(medium, max, true) {

        > li {
            padding: 20px 0;

            .e-img {
                width: 180px;

                img {
                    width: 100%;
                    height: 180px;
                }
            }

            .e-info {
                width: calc(100% - 180px);
                padding-right: 0;
            }

            .e-address {
                margin-top: 20px;
                width: 70%;
            }

            .e-btn {
                margin-top: 20px;
                width: 30%;
            }

            .e-certification {

                li {
                    padding: 10px 0;
                    width: 180px;
                }
            }
        }
    }

    @include mq(small, max, true) {

        > li {

            .e-img {
                width: 90px;

                img {
                    height: 80px;
                }
            }

            .e-info {
                width: calc(100% - 90px);

                dd {
                    line-height: 1.5;
                }

                ul {

                    li {
                        width: inherit;
                        @include css-lock(0.9, 0.9);
                        padding-left: 7px;
                        padding-right: 7px;
                    }
                }
            }

            .e-btn {

                a {
                    @include css-lock(1.1, 1.1);
                    background-position: right 7px center;
                }
            }

            .e-certification {

                li {
                    width: 90px;

                    img {
                        height: 40px;
                    }
                }
            }
        }
    }

    @include mq(xsmall, max, true) {

        > li {

            .e-info {

                ul {

                    li {
                        @include css-lock(0.8, 0.8);
                        padding-left: 4px;
                        padding-right: 4px;
                    }
                }
            }
        }
    }
}



.m-info-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;

    > div {
        width: 45%;

        > dl {
            margin-top: 40px;

            > dt {
                @include font-Amiri-R;
                @include css-lock(2.2, 2.0);
                font-weight: bold;
                line-height: 1.4;
            }

            > dd {
                display: flex;
                line-height: 1.8;

                .e-img {
                    width: 120px;

                    img {
                        width: 100%;
                    }

                    + .e-text {
                        width: calc(100% - 120px);
                        padding: 0 0 0 20px;
                    }
                }
            }
        }
    }

    @include mq(small, max, true) {
        display: block;

        > div {
            width: 100%;

            dl {

                dt {
                    text-align: center;
                }

                dd {
                    display: block;

                    .e-img {
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;

                        + .e-text {
                            width: 100%;
                            padding: 0;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}



.m-handling-list {
    display: flex;

    li {
        display: inline-block;
        padding: 0 30px;
        background-color: #f8f7cd;
        @include css-lock(1.2, 1.2);
        margin-left: 10px;
        text-align: center;

        &:first-child {
            margin-left: 0;
        }
    }

    @include mq(small, max, true) {

        li {
            width: calc((100% - 20px) / 3);
            //@include css-lock(0.9, 0.9);
            padding: 10px 0;
        }
    }

    @include mq(xsmall, max, true) {

        li {
            @include css-lock(1.0, 1.0);
        }
    }
}



.m-youtube-list {

    dl {
        margin-top: 50px;

        dt {
            @include font-mincho;
            @include css-lock(1.6, 1.6);
            margin-bottom: 10px;
            font-weight: bold;
            text-align: left;
        }
    }
}




.m-seminar-list {

    > li {

        a {
            display: block;
            background-color: $color-gray;

            .e-main {
                height: 180px;
                padding: 20px;

                &.pattern-01 {
                    height: 210px;
                }

                .e-tag {
                    display: flex;
                    margin-bottom: 10px;

                    li {
                        @include css-lock(1.0, 1.0);
                        color: $color-green-02;
                        border: 1px solid $color-green-02;
                        line-height: 1;
                        padding: 4px 5px 3px;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.e-area {
                            flex-basis: 75px;
                        }
                    }
                }

                .e-title {
                    @include css-lock(1.8, 1.8);
                    @include font-Amiri-R;
                    font-weight: bold;
                    line-height: 1.7;
                }

                @include mq(xlarge, max, true) {
                    height: 130px;

                    &.pattern-01 {
                        height: 160px;
                    }
                }

                @include mq(medium, max, true) {
                    height: 210px;

                    &.pattern-01 {
                        height: 240px;
                    }
                }

                @include mq(small, max, true) {
                    height: auto;

                    &.pattern-01 {
                        height: auto;
                    }
                }
            }

            .e-sub {
                text-align: center;
                border-top: 1px solid $color-gray-02;
                padding: 18px 0 15px;
                line-height: 1;
                background-image: url(../img/common/arrow-green-01.png);
                background-position: right 18px center;
                background-size: 7px auto;
                background-repeat: no-repeat;
            }

            &:hover {
                text-decoration: none;

                .e-main {

                    .e-title {
                        text-decoration: underline;
                    }
                }

                .e-sub {
                    background-color: $color-green-02;
                    color: $color-white;
                    background-image: url(../img/common/arrow-white-01.png);
                    background-size: 5px auto;
                }
            }
        }
    }
}


.m-seminar-list-02 {
    counter-reset: seminar-counter;
    list-style: none;

    > li {

        a {
            display: block;
            background-color: $color-gray;

            .e-title {
                @include css-lock(1.8, 1.8);
                @include font-Amiri-R;
                font-weight: bold;
                line-height: 1.4;
                padding: 20px 18px 15px;
                background-image: url(../img/common/arrow-green-01.png);
                background-position: right 18px center;
                background-size: 7px auto;
                background-repeat: no-repeat;

                &::before {
                    content: counter(seminar-counter);
                    counter-increment: seminar-counter;
                    color: $color-green;
                    display: inline-block;
                    line-height: 1.1;
                    text-align: center;
                    vertical-align: top;
                    height: 18px;
                    width: 18px;
                    border: 1px solid $color-green;
                    border-radius: 50%;
                    margin-right: 3px;
                }
            }

            &:hover {

                .e-title {
                    background-color: $color-green-02;
                    color: $color-white;
                    background-image: url(../img/common/arrow-white-01.png);
                    background-size: 5px auto;

                    &::before {
                        color: $color-white;
                        border: 1px solid $color-white;
                    }
                }
            }
        }
    }
}


.m-seminar-list-02-1 {
    counter-reset: seminar-counter2;
    list-style: none;
    margin-top: -60px;

    li {
        margin-top: 60px;
    }

    .e-title {
        @include css-lock(2.2, 2.2);
        @include font-Amiri-R;
        font-weight: bold;
        line-height: 1.4;
        border-bottom: 1px solid $color-gray-02;
        padding-bottom: 15px;
        margin-bottom: 50px;

        &::before {
            content: counter(seminar-counter2);
            counter-increment: seminar-counter2;
            color: $color-base;
            display: inline-block;
            line-height: 1.1;
            text-align: center;
            vertical-align: top;
            height: 22px;
            width: 22px;
            border: 1px solid $color-base;
            border-radius: 50%;
            margin-right: 5px;
        }
    }

    .e-move {
        position: relative;
        width: 100%;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    .e-pdf {

        dl {
            display: flex;
            align-items: center;
            margin-top: 20px;
        }

        dt {
            width: calc(100% - 120px);
            padding-right: 20px;
            line-height: 1.5;
        }

        dd {
            width: 120px;

            a {
                display: block;
                padding: 10px 0;
                border: 1px solid $color-gray-02;
                text-align: center;
                line-height: 1;
                background-image: url(../img/common/arrow-green-01.png);
                background-position: right 10px center;
                background-size: 6px auto;
                background-repeat: no-repeat;

                &:hover {
                    background-color: $color-green-02;
                    color: $color-white;
                    background-image: url(../img/common/arrow-white-01.png);
                    background-size: 4px auto;
                    text-decoration: none;
                }
            }
        }
    }

    @include mq(medium, max, true) {

        margin-top: -60px !important;

        li {
            margin-top: 60px !important;
        }
    }

    @include mq(small, max, true) {

        margin-top: -60px;

        li {
            margin-top: 60px;
        }

        .e-title {
            margin-bottom: 25px;
        }
    }
}